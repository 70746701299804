.Container {
    height: 100vh;

    position: relative;
}

.Video,
.Video video {
    width : 100vw;
    height: 100vh;
}

.Background {
    background-color: rgba(0, 0, 0, 0.5);

    height: 100vh;
    width : 100%;

    position: fixed;
    top     : 0;
    left    : 0;
    z-index : 1;

    padding-left: 120px;
    padding-top : 120px;

    pointer-events: none;
}

.ControlsOff .Background {
    display: none;
}

.BackButton {
    position: absolute;
    top     : 3rem;
    left    : 3rem;
    z-index : 4;

    color: #FFF;

    border: none;

    pointer-events: initial;
}

.BackButtonArrow {
    font-size: 1.5rem;

    margin-right: 3px;
}

.Info {
    display          : flex;
    flex-direction   : column;
    /* align-items   : center; */

    position: relative;

    max-width: 40%;
}

.ControlPanel {
    position: fixed;
    left    : 0;
    bottom  : 0;

    width  : 100%;
    z-index: 2;

    transition-property       : bottom;
    transition-duration       : 0.7s;
    transition-timing-function: ease-out;

    display       : flex;
    flex-direction: column;
}

.ControlsOff .ControlPanel {
    bottom: -50vh;
}

/* CHANNEL PLAYER */
.ChannelLogo {
    position: absolute;

    left: 5vw;
}

.ChannelLogo>img {
    max-height: 100px;
    max-width : 100px;
    object-fit: cover;
}

.ChannelLogo>p {
    font-size  : var(--font-30);
    font-weight: bold;

    color: var(--main-text);

    max-width: 200px;
}

.Epg, .Movie {
    width: 100%;

    display        : flex;
    flex-direction : column;
    justify-content: space-between;
}

.EpgTime {
    font-weight: 400;
    font-size  : var(--font-18);
    line-height: 120%;

    color: var(--main-text);

    margin-bottom: 4px;
}

.EpgTitle {
    font-weight: 700;
    font-size  : var(--font-30);
    line-height: 120%;

    color: var(--main-text);
}

.FingerPrint {
    position: fixed;

    background-color: rgba(0, 0, 0, 0.5);
    color           : var(--main-text);

    font-size: var(--font-20);

    padding: 30px 40px;

    max-width : 490px;
    max-height: 85px;
}

/* MOVIE PLAYER */
/* PLAYLIST PLAYER */
.Button {
    position: absolute;
    right   : 5%;
    top     : -100px;

    color       : var(--main-color);
    border-color: var(--main-color);

    padding-left : 37px;
    padding-right: 37px;

    display        : flex;
    justify-content: center;
    align-items    : center;

    cursor: pointer;
}

.Button svg {
    pointer-events: none;
}

.ControlsOff .ControlPanel .Button {
    top   : unset;
    bottom: calc(50vh + 100px);
}

.Year {
    font-weight: 400;
    font-size  : var(--font-18);
    line-height: 120%;

    color: var(--main-text);

    margin-bottom: 4px;
}

.Name {
    font-weight: 700;
    font-size  : var(--font-30);
    line-height: 120%;

    color: var(--main-text);
}