.ContentSection {
    padding     : 0;
    padding-left: 3vw;

    position: relative;
}

.ListHeader {
    font-weight: 400;
    font-size  : var(--font-21);
    line-height: var(--line-120);

    text-align: left;

    color: var(--main-text);

    position: absolute;
    top     : -40px;
}

.ContentList {
    height: 50vh;

    overflow-y: auto;

    padding-bottom: 15%;
}

.ContentList>div {
    margin-right : 5px;
    margin-bottom: 5px;
}