.Container {
    width : max-content;
    height: max-content;

    position: relative;
}

.Content {
    overflow-x: auto;

    max-width: 100vw;
}

.ListArrowLeft,
.ListArrowRight {
    position: absolute;
    top     : 0;

    z-index: 2;

    width : 40px;
    height: 100%;

    display        : flex;
    align-items    : center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.5);

    color: gainsboro;

    cursor: pointer;

    user-select: none;
}

.ListArrowLeft {
    left: -5px;
}

.ListArrowRight {
    left: calc(100vw - 40px - 30px);
    /*30px padding*/
}

.ListArrowLeft:hover,
.ListArrowRight:hover {
    background-color: rgba(0, 0, 0, 0.8);
}