.Wrapper {
	height        : 100%;
	display       : flex;
	flex-direction: row;
	overflow      : hidden;
	white-space   : break-spaces;
}

.Wrapper>main {
	flex: 1;

	width: calc(100vw - 80px - 80px);

	position  : relative;
	height    : 100vh;
	overflow-y: hidden;
}


.Modal {
	min-width: 575px !important;
}

.Header {
	font-weight  : 700;
	font-size: var(--font-30);
	line-height  : 120%;
	margin-bottom: 34px;
}

.Text {
	font-weight: 400;
	font-size: var(--font-23);
	line-height: 145%;
}