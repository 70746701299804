.Container {
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
}


.Heading {
    margin-bottom: 29px;
}

.Header {
    font-weight: 400;
    font-size  : var(--font-24);
    line-height: var(--line-120);

    color: var(--main-text);

    margin-bottom: 15px;
}

.Text {
    font-weight: 400;
    font-size  : var(--font-21);
    line-height: var(--line-200);

    color: var(--04-vucos-grey);
}

.Content {
    display: flex;

    width: calc(100vw - 390px - 10px - 72px);

    overflow-x: auto;
}

.Column {
    display       : flex;
    flex-direction: column;
}