.Container {
    display          : flex;
    flex-direction   : column;
    /* max-height    : calc(100vh - 117px); */
    max-height       : 100vh;

    padding-left : 50px;
    padding-top  : 105px;
    padding-right: 50px;
}

.Container>*:first-child {
    min-height: 200px;

    overflow-y: hidden;
}

.ProfileCenter {
    max-height: calc(100vh - 220px);
    overflow-y: auto;
}

.ProfileCenter>*:last-child {
    margin-bottom: 30vh;
}