.Item {
    display: flex;
}
 
.Item img {
    height         : auto;
    width          : 20vw;
    object-fit     : contain;
    object-position: center;
    border-radius  : 4px;

    margin-right: 34px;
}

.Detail {
    font-weight: 700;
    font-size: var(--font-20);
    line-height: 120%;

    /* 02-vucos-grey */
    /* color: var(--02-vucos-grey); */
    color: var(--main-text);

    width    : 70%;
    max-width: 660px;

    display        : flex;
    flex-direction : column;
    justify-content: space-around;

}

.Title {}

.Description {
    font-weight: 400;

    color: var(--04-vucos-grey);

    display           : -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.Year {
    font-weight: 400;
}

.OtherInfo {
    display: flex;

    font-weight: 300;
    font-size: var(--font-18);

    /* color: var(--05-vucos-grey); */
    color: var(--main-text);
}

.OtherInfo time {
    margin-right: 15px;

    white-space: nowrap;
}

.OtherInfo p {
    display           : -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}