.Container {
    background: linear-gradient(90deg, var(--09-vucos-grey) 0%, var(--11-vucos-grey) 100%);
    color     : var(--main-text);

    height  : 100vh;
    width   : 100vw;
    overflow: hidden;

    display: flex;
}

.Container>nav {
    max-width: 350px;
    min-width: 350px;
}

.Section {
    background-position: center;
    background-size    : cover;

    flex: 1 1 0;

    padding-top : 90px;
    padding-left: 60px;

    height: 100vh;
    overflow: hidden;
}