.InputWrapper {
    position: relative;
}

.InputWrapper input {
    display      : block;
    width        : 100%;
    height       : 50px;
    margin-bottom: 30px;
    padding-left : 22px;
    background   : var(--08-vucos-grey);
    border       : 2px solid var(--08-vucos-grey);
    border-radius: 8px;
    font-size    : 17px;
    line-height  : 120%;
    color        : var(--02-vucos-grey);
}

.InputWrapper input::placeholder {
    color: var(--06-vucos-grey);
}

.InputWrapper input[type="password"]:not(:placeholder-shown) {
    font-size: var(--font-50);
}

.InputWrapper.Focused input:focus,
.InputWrapper.Focused input:active,
.InputWrapper.Focused input,
.InputWrapper:hover input {
    outline     : none;
    border-color: var(--main-color);
}

.InputWrapper input:focus,
.InputWrapper input:active,
.InputWrapper:hover input {
    outline     : none;
    border-color: var(--main-color);
}

.HideLabel {
    display: none;
}

.ShowLabel {
    display      : block;
    font-weight  : 400;
    font-size    : var(--font-20);
    line-height  : var(--line-120);
    color        : var(--06-vucos-grey);
    margin-bottom: 10px;
}

.InsideLabel {
    position: absolute;
    left    : -10000px;
    width   : 1px;
    height  : 1px;
    overflow: hidden;

    font-size     : 15px;
    line-height   : 120%;
    font-weight   : normal;
    letter-spacing: 0em;

    color: var(--04-vucos-grey);

    text-align: left;
}

.InputWrapper.Filled .InsideLabel {
    width : unset;
    height: unset;

    display: inline-block;

    position: absolute;
    top     : 13px;
    left    : 24px;
}

.PasswordShow {
    display    : flex;
    align-items: center;

    height: 100%;

    position: absolute;
    top     : 0px;
    right   : 19px;

    cursor: pointer;

    color: var(--04-1-vucos-grey);
}

.InputWrapper.Filled input:focus+label,
.InputWrapper.Filled input:focus~span {
    color: var(--main-color);
}

.HiddenInput input {
    -webkit-text-security: disc;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}