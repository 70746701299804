.Button {
    background-color: var(--none);
    font-size       : 1rem;
    line-height     : 120%;
    color           : var(--02-vucos-grey);
    border          : 1px solid var(--02-vucos-grey);
    border-radius   : 8px;
    overflow        : hidden;
    white-space     : nowrap;
    text-overflow   : ellipsis;
    cursor          : pointer;

    padding-left : 20px;
    padding-right: 20px;
}

.Button svg {
    pointer-events: none;
}

.Button:disabled {
    opacity       : 0.5;
    pointer-events: none;
}

/* .Button:disabled {
    border-color: #6e6e6e;
    color       : #6e6e6e;
} */

.Small {
    min-width    : 107px;
    height       : 40px;
    padding-left : .6rem;
    padding-right: .6rem;
}

.Large {
    width : 235px;
    height: 40px;
}

.Button.Focused,
.Button:not(.Disabled):hover {
    background: var(--main-color);
    color     : var(--main-text);
}

.Disabled {
    opacity: 0.3 !important;
    cursor : not-allowed !important;
}

.Button.Active:not(.Focused) {
    background: var(--07-vucos-grey);
    color     : var(--main-text);
}

.Centered {
    margin-left : auto;
    margin-right: auto;
    display     : block;
}