.Container {
    position: absolute;
    right   : 96px;
    top     : 30px;

    height: 100%;

    display    : flex;
    align-items: center;

    cursor: pointer;
}

.LiveContainer {
    padding: 9.5px 8px;

    display    : flex;
    align-items: center;

    border       : 1px solid var(--main-text);
    border-radius: 4px;

    background-color: transparent;
}

.Focused.LiveContainer,
.LiveContainer:hover {
    background-color: var(--main-color);
}

.LiveIcon {
    display: inline-block;

    width        : 13px;
    height       : 13px;
    border-radius: 50%;

    background-color: transparent;
    border          : 1px solid white;

    margin-right: 5px;
}

.Live .LiveIcon {
    /* border-color: var(--main-color); */
    background: var(--main-color);
}

.Focused .LiveIcon,
.LiveContainer:hover .LiveIcon {
    border-width: 2px;
}

.LiveText {
    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 600;
    font-size  : 15px;
    line-height: 120%;

    color: var(--main-text);
}