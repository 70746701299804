.Item {
    background: var(--08-vucos-grey);

    border-radius: 8px;

    padding      : 20px 15px;
    margin-bottom: 15px;

    width: 95%;
    max-width: 828px;

    display        : flex;
    justify-content: space-between;
    align-items    : center;
}

.Text {
    font-weight: 500;
    font-size: var(--font-19);
    line-height: 120%;

    color: var(--main-text);

    margin-right: 20px;
}

.Content {
    display: flex;
}

.Content>* {
    margin-right: 20px;
}

.Content>*:last-child {
    margin-right: 0;
}