.Button {
    font-weight: 400;
    font-size  : var(--font-21);
    line-height: 120%;

    color: var(--02-vucos-grey);

    border       : 1px solid transparent;
    border-radius: 8px;

    width : 250px;
    height: 60px;

    display        : flex;
    justify-content: center;
    align-items    : center;
}

.Focused {
    background  : var(--main-color);
    /* 09-vucos-grey */
    color       : var(--main-text);
    border-color: var(--main-color);
}


.Button svg {
    margin-right: 15px;
}

.Button path {
    fill: var(--main-text);
}

.Button.Focused path {
    fill: white;
}