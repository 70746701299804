.Item {
    display: flex;

    width: 100%;
}

.Item img {
    height         : auto;
    width          : 20vw;
    object-fit     : contain;
    object-position: center;
    border-radius  : 4px;

    margin-right: 34px;
}

.Detail {
    /* width: 450px; */
    /* width: 100%; */

    display        : flex;
    flex-direction : column;
    justify-content: space-between;
}

.LiveIcon {
    display: inline-block;

    width        : 11px;
    height       : 11px;
    border-radius: 50%;

    background: var(--red);
    border    : 1px solid var(--red);

    margin-right: 4px;
    margin-left : 12px;
}

.LiveText {
    display    : flex;
    align-items: center;

    font-weight: 400;
    font-size  : var(--font-20);
    line-height: 136%;

    color: var(--main-text);
}

.Title {
    font-weight: 700;
    font-size  : var(--font-30);
    line-height: 120%;

    color: var(--main-text);

    margin-bottom: 10px;

    display           : -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.Text {
    font-weight: 400;
    font-size  : var(--font-18);
    line-height: 100%;

    color: var(--03-vucos-grey);

    margin-bottom: 10px;

    display           : -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.Time {
    font-weight: 400;
    font-size  : var(--font-20);
    line-height: 136%;

    /* 03 */
    color: var(--03-vucos-grey);
}