.Item {
    flex-shrink : 0;
    margin-right: 2px;
    position    : relative;

    cursor: pointer;

    --channel-width: calc(var(--channel-w) * 0.85);
}

.Item.LastItem {
    padding-right: calc(100% - var(--channel-width) - 5px) !important;
}

.FocusPoint {
    height  : 40px;
    position: absolute;
    top     : -53px;
    width   : 100%;
}

.Wrapper {
    width   : var(--channel-width);
    height  : calc(var(--channel-h) * 0.85);
    position: relative;
    border  : 5px solid transparent;

    border-radius: 4px;
}

.Focused .Wrapper {
    border-color: var(--main-color);
}

.ImgContainer {
    width              : 100%;
    height             : 100%;
    background-size    : contain !important;
    background-repeat  : no-repeat !important;
    background-position: center !important;
    background-color   : rgba(22, 22, 29, 0.8);
    border-radius      : 4px;
    position           : relative;

    display        : flex;
    justify-content: center;
    align-items    : center;
}

.Title {
    width             : calc(0.9 * var(--channel-width));
    margin-top        : 10px;
    font-weight       : 400;
    font-size         : 1rem;
    line-height       : 120%;
    text-align        : center;
    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow          : hidden;

    color: var(--05-vucos-grey);
}

.Name {
    width: calc(0.8 * var(--channel-width));

    font-weight: 500;
    font-size  : var(--font-25);
    line-height: 120%;
    text-align : center;

    color: var(--main-text);
}

.Logo {
    width        : 22%;
    margin-bottom: 12px;
    position     : absolute;
    bottom       : 0;
    left         : 39%;
    z-index      : 1;
}

.Focused .Title {
    /* .Title:hover  */
    color: var(--02-vucos-grey);
}