.HeaderSection {
    display: flex;
    flex-wrap: wrap;
}

.HeaderSection>div {
    width: 45%;
    min-width: fit-content;
    /* max-width: 40%;
    min-width: fit-content;

    padding: 0 10px;

    white-space: no-wrap; */
}

.ContentSection {
    margin-top: 30px;

    height    : calc(100vh - 211px);
    overflow-y: auto;

    display    : flex;
    flex-wrap  : wrap;
    align-items: stretch;
}

