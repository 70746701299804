.Container {
    position: fixed;
    top     : 0;
    left    : 0;

    width : 100vw;
    height: 100vh;

    z-index: 1;

    pointer-events: none;

    opacity: 0.8;
}

.Container img {
    position: absolute;
}

.Left {
    top : 0;
    left: 0;

    height: 100%;
}

.Right {
    top  : 0;
    right: 0;

    height: 100%;
}

.Top {
    top : 0;
    left: 0;

    width: 100%;
}

.Bottom {
    bottom: 0;
    left  : 0;

    width: 100%;
}