.Container {
    color: var(--main-text);

    height: 100vh;

    max-width: calc(100vw - 80px);
    min-width: calc(100vw - 80px);

    overflow: hidden;

    display: flex;
}

.Container>nav {
    min-width: fit-content;
    max-width: fit-content;

    height    : 100vh;
    overflow-y: hidden;
}

.Section {
    background: var(--09-vucos-grey);

    height: 100vh;

    flex    : 1 1 0;
    overflow: hidden;

    background-image   : url("../../assets/img/img-bg.png"); 
    background-position: center;
    background-size    : cover;
}