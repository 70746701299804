.Container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.Header {
	font-weight: 700;
	font-size: var(--font-33);
	line-height: 125%;
    color: var(--main-text);
	width: 33%;
}

.Text {
	margin-top: 31px;
	margin-bottom: 30px;
	font-weight: 400;
	font-size: var(--font-22);
	line-height: 125%;
    color: var(--main-text);
}