.Item {
    text-align: center;
}

.Img {
    display   : block;
    width     : calc(var(--channel-w) * 0.7);
    height    : calc(var(--channel-h) * 0.7);
    object-fit: cover;

    border-radius: 4px;
}

.Title {
    margin: 10px 0;

    font-weight: 300;
    font-size: var(--font-23);
    line-height: 120%;

    /* color: var(--05-vucos-grey); */
    color: var(--main-text);

    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.Selected .Title {
    font-weight: 700;
    color: var(--main-color);
}


/* .Active {
    border-color: var(--main-color);
} */