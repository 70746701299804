.Item {
    position: relative;

    font-weight: 400;
    font-size  : var(--font-18);
    line-height: var(--line-120);

    color: var(--02-vucos-grey);

    margin-bottom: 5px;
    margin-right : 5px;

    border-radius: 8px;

    /* min-width       : 274px; */
    height          : 50px;
    background-color: transparent;

    display    : flex;
    align-items: center;

    padding-left : 32px;
    padding-right: 10px;

    cursor: pointer;
}

.Item.Focused,
.Item:hover {
    background: var(--main-color);
    color     : var(--main-text);
}

.Today::before {
    content      : "";
    width        : 15px;
    height       : 15px;
    display      : inline-block;
    background   : red;
    border-radius: 50%;

    position: absolute;
    left    : -25px;

    animation: appear 1s infinite;
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 0.6;
    }

    100% {
        opacity: 1;
    }
}

.Selected {
    background: var(--07-vucos-grey);
    color     : var(--main-text);
}