.Navbar {
    background-color: linear-gradient(90deg, var(--09-vucos-grey) 37.5%, var(--11-vucos-grey) 100%);

    display        : flex;
    flex-direction : column;
    justify-content: space-between;

    padding: 84px 41px 40px 45px;
}

.List {
    list-style: none;
}

.List li {
    margin-bottom: 4px;
}

.NavLink,
.NavCheck {
    font-weight: 400;
    font-size  : var(--font-19);
    line-height: var(--line-120);

    color: var(--02-vucos-grey);

    padding: 15px 23px;

    border       : 1px solid transparent;
    border-radius: 8px;
}


.NavLink {
    text-decoration: none;

    display: block;
}

.NavCheck {
    display        : flex;
    justify-content: space-between;

    padding-right: 15px;
}

.Active {
    background: var(--07-focus-grey);
}

.Focus,
.NavLink:hover,
.NavCheck:hover {
    background  : var(--main-color);
    color       : var(--main-text);
    border-color: var(--main-color);
}

.Button {
    font-weight: 400;
    font-size  : var(--font-19);
    line-height: var(--line-120);

    width : 100%;
    height: 50px;

    margin-bottom: 15px;
}

.Shape {
    display: none;
}

.FocusedImg .Shape {
    display: inline-block;

    top     : 0;
    left    : 0;
    position: absolute;
    width   : 100%;
    height  : 100%;
}