.Container {
    position: fixed;

    top : 0;
    left: 0;

    width : 100vw;
    height: 100vh;

    display        : flex;
    justify-content: center;
    align-items    : center;

    background-color: rgba(0, 0, 0, 0.5);
}

.Content {
    display       : flex;
    flex-direction: column;
    align-items   : center;
}

.Header {
    font-weight: 700;
    font-size: var(--font-30);
    line-height: 120%;

    /* 06-vucos-grey */
    color: var(--main-text);

    margin-bottom: 18px;
}

.Input input {
    font-size: var(--font-25);
    font-weight: 400;

    color: var(--main-text);
    text-align: center;

    padding-left: 0;
}

.ActionContainer {
    display: flex;
    justify-content: center;
}

.ActionContainer>* {
    margin: 0 10px;

}