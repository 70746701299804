.ListSection {
    overflow-y: auto;
    overflow-x: hidden;

    display       : flex;
    flex-direction: column;

    margin-top: 0;
    /*50px;*/
    position: relative;
}

.ListSection>div:last-of-type {
     margin-bottom: calc(60vh - 325px - 57px); 
}