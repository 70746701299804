.Item {
    display: flex;

    position: relative;

    color: var(--main-text);

    margin-bottom: 5px !important;

    cursor: pointer;

    border       : 5px solid transparent;
    border-radius: 4px;

    width : 100%;
    height: fit-content;
}

.ImageWrapper {
    max-width : calc(var(--channel-w) * 0.7);
    min-width : calc(var(--channel-w) * 0.7);
    height: calc(var(--channel-h) * 0.7);

    margin-right: 15px;
}

.ImgContainer {
    width : 100%;
    height: 100%;

    background-size    : cover;
    background-position: center;

    border-radius: 4px;
}

.Focused,
.Item:hover {
    border-color: var(--main-color);
}

.Detail {
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
}

.Channel {
    display    : flex;
    align-items: center;
}

.ChNumber {
    min-width: 54px;
    height   : 35px;

    padding-top   : 6px;
    padding-bottom: 4px;
    text-align    : center;

    font-weight: 400;
    font-size  : var(--font-20);
    line-height: 125%;


    /* 06-vucos-grey */
    /* color: var(--06-vucos-grey); */
    color: var(--main-text);
}

.Logo {
    margin-right: 10px;

    height: 40px;
    width : auto;
}

.Show {
    font-size  : var(--font-18);
    line-height: 130%;

    /* color: var(--05-vucos-grey); */
    color: var(--main-text);
}

.Time {
    display: flex;
}

.Time time {
    font-size  : var(--font-18);
    font-weight: 500;
    line-height: var(--line-130);
}

.ChName {
    font-size  : var(--font-18);
    font-weight: 500;
    line-height: var(--line-130);

    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.Title {
    margin-top: 10px;

    font-size  : var(--font-20);
    font-weight: 700;
    line-height: var(--line-130);

    color: var(--main-text);

    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.Progress {
    display    : flex;
    align-items: center;
}

.TotalLine {
    width : 190px;
    height: 10px;

    border: 1px solid var(--06-vucos-grey);

    margin-right: 10px;
}

.CompletedLine {
    background: var(--03-vucos-grey);

    height: 100%;
}

.ProgressText {
    font-weight: 400;
    font-size  : var(--font-18);
    line-height: 136%;

    /* 06-vucos-grey */
    /* color: var(--06-vucos-grey); */
    color: var(--main-text);
}

.PlaceHolder {
    height: 25px;
    width : 100%;
}