.Container {
    display    : flex;
    align-items: center;
}

.Img {
    width        : 46px;
    height       : 46px;
    object-fit   : cover;
    border-radius: 50%;

    margin-right: 34px;
}

.Name {
    font-style : normal;
    font-weight: 400;
    font-size: var(--font-25);
    line-height: 120%;

    color: var(--02-vucos-grey);

    padding: 0px 0px 5px 13px;
}

.Link {
    font-style : normal;
    font-weight: 500;
    font-size: var(--font-20);
    line-height: 120%;

    text-decoration: none;

    padding  : 5px 10px;
    min-width: 165px;
    height   : 50px;

    border-color: transparent;
}


.LogoContainer {
    position: absolute;
    top     : 40px;
    left    : 15px;
    z-index : 1;
}