.Container {
    height: 100vh;
}

.Video,
.Video video {
    width : 100vw;
    height: 100vh;
}

.Background {
    background-color: rgba(0, 0, 0, 0.5);

    height: 100vh;
    width : 100vw;

    position: fixed;
    top     : 0;
    left    : 0;
    z-index : 1;

    padding-left: 120px;
    padding-top : 120px;

    pointer-events: none;
}

.ControlsOff .Background {
    display: none;
}

.Info {
    max-width: 30%;
}

.Year {
    font-weight: 400;
    font-size  : var(--font-18);
    line-height: var(--line-120);

    color: var(--main-text);

    margin-bottom: 4px;
}

.Name {
    font-weight: 700;
    font-size  : var(--font-30);
    line-height: var(--line-120);

    color: var(--main-text);
}

.ControlPanel {
    position: absolute;
    left    : 0;
    bottom  : 8vh;

    width  : 100%;
    z-index: 2;

    transition-property       : bottom;
    transition-duration       : 0.8s;
    transition-timing-function: ease-out;

    display       : flex;
    flex-direction: column;
}

.ControlsOff .ControlPanel {
    bottom: -50vh;
}

.Button {
    position: absolute;
    right   : 5%;
    top     : -100px;

    color       : var(--main-color);
    border-color: var(--main-color);

    padding-left : 37px;
    padding-right: 37px;

    display        : flex;
    justify-content: center;
    align-items    : center;
    cursor         : pointer;
}

.Button svg {
    pointer-events: none;
}

.ControlsOff .ControlPanel .Button {
    top   : unset;
    bottom: calc(50vh + 100px);
}

.BackButton {
    position: absolute;
    top     : 3rem;
    left    : 3rem;
    z-index : 4;

    color: #FFF;

    border: none;

    pointer-events: initial;
}

.BackButtonArrow {
    font-size: 1.5rem;

    margin-right: 3px;
}