.Input input {
    width : 100%;
    height: 70px;

    padding: 13px 24px;

    border-radius: 4px;
}

.Input.Filled input {
    padding: 33px 24px 13px;
}

.Input.Filled input:focus+label {
    color: var(--main-color);
}

.Button {
    width : 100%;
    height: 60px;
}