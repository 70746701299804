.Container {
    height: 100%;

    display        : flex;
    flex-direction : column;
    justify-content: space-between;
}

.Content {
    height: 80%;

    overflow: auto;
    scroll-behavior: smooth;

    display        : flex;
    flex-direction : column;
    justify-content: flex-start;
}

.Content h1,
.Content h2,
.Content h3 {
    font-weight: 700;
    font-size  : var(--font-22);
    line-height: var(--line-120);

    color: var(--main-text);

    margin-bottom: 30px;
}

.Content p {
    font-weight: 400;
    font-size  : var(--font-20);
    line-height: var(--line-120);

    color: var(--02-vucos-grey);

    margin-bottom: 20px;

    white-space: break-spaces;
}

.Control {
    display    : flex;
    align-items: center;
}

.Control * {
    display: inline-block;
}

.Control>* {
    margin-right: 30px;
}

.Button {
    border: none;

    min-width: unset;
    height   : fit-content;

    color: var(--main-text);
}

.Button.Focused,
.Button:hover {
    background-color: unset !important;
    border          : none !important;

    outline: none !important;
}

.Button.Focused rect,
.Button:hover rect {
    fill: var(--main-color);
}

.Page {
    font-weight: 300;
    font-size  : var(--font-23);
    line-height: var(--line-130);

    color: #FAFAFA;
}