.Navbar {
	height: 100vh;
	width : 30vw;

	min-width: fit-content;
	max-width: 360px;

	overflow-y: scroll;

	padding: 50px 30px 10px 40px;

	background: radial-gradient(100% 15.78% at 0% 54.4%, var(--09-vucos-grey) 55.73%, var(--10-vucos-grey) 100%);
}

.Container {
	display       : flex;
	flex-direction: column;
}

.Section {
	margin-bottom: 10vh;
}

.SectionTitle {
	font-weight: 500;
	font-size  : var(--font-18);
	line-height: var(--line-120);

	color: var(--main-text);
}

.ActionContainer {
	display       : flex;
	flex-direction: column;
}

.ActiveValue {
	font-weight: 400;
	font-size  : var(--font-17);
	line-height: var(--line-120);

	color        : var(--04-1-vucos-grey);
	margin-top   : 15px;
	margin-bottom: 15px;
}

.ActionContainer>p:last-of-type {
	margin-top: 0;
}

.ChangeButton {
	font-weight: 400;
	font-size  : var(--font-18);
	line-height: var(--line-120);

	height: 40px;
	width : 100%;

	color: var(--main-text);

}