.Container {}

.Background {
    position: fixed;
    top     : 0;
    left    : 0;
    z-index : 200;

    width : 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.491);
}

.Content {
    position: fixed;
    top     : 0;
    right   : -1000px;

    /* background-color: red; */

    z-index: 201;

    height: 100%;
    width : fit-content;

    animation-name           : fromright;
    animation-duration       : 1s;
    animation-fill-mode      : forwards;
    animation-timing-function: ease-in-out;
}

@keyframes fromright {
    from {
        right: -1000px;
    }

    to {
        right: 0px;
    }
}