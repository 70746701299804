.Item {
    text-align: center;
}

.ImgContainer {
    position: relative;

    display        : flex;
    justify-content: center;
}

.Img {
    width     : calc(329px * 0.8);
    height    : calc(184px * 0.8);
    object-fit: cover;

    border-radius: 4px;

    /* margin: 23px 0 5px 0; */
}

.Title {
    margin-top: 5px;

    font-weight: 300;
    font-size: var(--font-23);
    line-height: var(--line-120);

    /* color: var(--05-vucos-grey); */
    color: var(--main-text);

    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.Playing .Title {
    font-weight: 700;

    /* color: var(--02-vucos-grey); */
    color: var(--main-color);
} 