.Backdrop {
    width : 100%;
    height: 100%;

    position: fixed;
    top     : 0;
    right   : 0;

    z-index: 2;

    background: linear-gradient(90deg, var(--09-vucos-grey) 21.4%, rgba(22, 22, 29, 0.46) 100%);
}

.Outlet {
    width : 100%;
    height: 100%;

    position: fixed;
    top     : 0;
    right   : 0;

    z-index: 1;

    background-color: black;
    opacity: 0.8;
}