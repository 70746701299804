.Item {
    position: relative;

    display        : flex;
    align-items    : center;
    justify-content: space-between;

    min-width: fit-content;
    width    : 100%;
    padding  : 0;

    border: 1px solid var(--none);

    cursor: pointer;

    color: white;
}

.Item>div {
    display    : flex;
    align-items: center;
}

.Item.Focused {
    /* border-color: var(--main-color); */
}

.LineContainer {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
}

.Ball {
    width : 25px;
    height: 25px;

    border-radius: 50%;
    border       : 2px solid white;
}

.Focused .Ball {
    background: var(--none);

    border-color: var(--main-color);
    border-width: 2px;
}

.Active .Ball {
    background: var(--main-color);

    border-color: var(--main-color);
}


.Logo {
    width : 30px;
    height: 30px;

    margin-right: 20px;
}

.Text {
    margin-right: 10px;

    font-weight: 400;
    font-size  : var(--font-19);
    line-height: var(--line-100);

    text-align: center;

    white-space: no-wrap;
}

.Passive>* {
    opacity: 0.5;

    cursor: not-allowed;
}