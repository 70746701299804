.Container {
	width     : 100vw;
	height    : 100vh;
	overflow  : hidden;
	background: var(--loading_bgcolor);
	position  : fixed;
	z-index   : 1000;
	top       : 0;
	left      : 0;
}

.LogoContainer {
	height         : 100%;
	width          : 100%;
	display        : flex;
	justify-content: center;
	align-items    : center;
}

.ImgLogo {
	width                    : 20%;
	animation-name           : loading;
	animation-duration       : 3s;
	animation-iteration-count: infinite;
}

@keyframes loading {
	0% {
		transform: scale(1);
		opacity  : 1;
	}

	25% {
		transform: scale(1.1);
		opacity  : 0.5;
	}

	50% {
		transform: scale(1);
		opacity  : 1;
	}

	75% {
		transform: scale(1.1);
		opacity  : 0.5;
	}

	100% {
		transform: scale(1);
		opacity  : 1;
	}

}