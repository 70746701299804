.PlayerActions {
    position: relative;

    width: 100vw;

    display        : flex;
    justify-content: center;
    align-items    : center;

    margin: 24px 0 0px;
}

.Button {
    margin-right: 120px;
    /* color    : var(--04-1-vucos-grey) !important; */
    color       : white !important;
    background  : transparent;
    cursor      : pointer;
}

.Button svg {
    pointer-events: none;
}

.Button.Focused,
.Button:hover {
    color: var(--main-color) !important;
}

.PlayerActions>button:last-of-type {
    margin-right: 0;
}