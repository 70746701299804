.FullContainer {
	position: fixed;
	top     : 0;
	left    : 0;

	z-index: 2;

	width : 100vw;
	height: 100vh;

	background: rgba(0, 0, 0, 0.8);

	display        : flex;
	justify-content: center;
	align-items    : center;
}

.FullContainerContent {
	max-width : 60%;
	max-height: 80%;

	padding      : 64px 39px 56px;
	border-radius: 12px;

	background: var(--09-vucos-grey);
	box-shadow: 0px 4px 100px #000000;

	display       : flex;
	flex-direction: column;
	align-items   : center;
}

.Header {
	font-style : normal;
	font-weight: 700;
	font-size  : var(--font-22);
	line-height: var(--line-120);

	color: var(--main-text);
}

.Content {
	font-weight: 400;
	font-size  : var(--font-19);
	line-height: var(--line-145);
	text-align : center;

	color: var(--main-text);

	margin: 20px 0 30px;

}

.Button {
	border-radius: 8px;

	font-weight: 400;
	font-size  : var(--font-19);
	line-height: var(--line-120);

	padding  : 15px 0;
	min-width: 250px;
	max-width: 40%;
	height   : unset;
}

.Focused {
	color: var(--main-text);

	background: var(--main-color);
}