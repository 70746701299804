.Container {}

.Section {
    position: fixed;
    top     : 0;
    right   : -200px;

    z-index: 100;

    height: 100%;

    animation-name           : fromright;
    animation-duration       : 1s;
    animation-fill-mode      : forwards;
    animation-timing-function: ease-in-out;

}

@keyframes fromright {
    from {
        right: -1000px;
    }

    to {
        right: 0px;
    }
}