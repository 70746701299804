/* .Container {} */

/* .TextContent {} */

.Heading {
	/* color      : var(--02-vucos-grey_headings); */
	color: var(--main-color);

	font-size  : var(--font-30);
	line-height: var(--line-200);
	font-weight: 500;
	text-align : center;
}

.Text {
	font-size     : var(--font-18);
	font-weight   : 300;
	padding-bottom: 20px;
	text-align    : center;
	white-space   : break-spaces;
}

.Form {
	width : 70%;
	margin: 1.5rem auto 0;
}

.Buttons {
	display        : flex;
	justify-content: space-between;
	position       : absolute;
	left           : 0;
	padding-left   : 105px;
	padding-right  : 103px;
	bottom         : 123px;
	width          : 52.09%;
}

.Captcha {
	display        : flex;
	justify-content: center;
}