.Item {
    font-weight: 500;
    font-size: var(--font-18);
    line-height: 120%;

    color: var(--main-text);

    display    : flex;
    align-items: center;

    cursor: pointer;
}

.Item.Focused,
.Item:hover {
    color: var(--main-color);
}

.Input {
    -webkit-appearance: none;
    appearance        : none;
    background-color  : transparent;
    margin            : 0;

    width        : calc(30px * 0.6);
    height       : calc(30px * 0.6);
    border       : 2px solid var(--main-text);
    border-radius: 50%;

    display        : flex;
    align-items    : center;
    justify-content: center;

    cursor: pointer;
}

.Active.Input {
    border-color: var(--main-color);
}

.Input::before {
    content      : "";
    display      : inline-block;
    width        : calc(18px * 0.56);
    height       : calc(18px * 0.56);
    border-radius: 50%;
    transform    : scale(0);
    transition   : 120ms transform ease-in-out;
    box-shadow   : inset calc(18px * 0.56) calc(18px * 0.56) var(--main-color);

    cursor: pointer;
}

.Input.Active::before {
    transform: scale(1);
}

.Item label {
    margin-left: 10px;

    cursor: pointer;
}