.Helper {
    /* 09-vucos-grey */
    background   : var(--09-vucos-grey) !important;
    box-shadow   : 0px 4px 100px #000000 !important;
    border-radius: 12px !important;

    padding: 20px 25px 25px !important;

    display       : flex !important;
    flex-direction: column !important;
    align-items   : center !important;

    text-align: center !important;

    /* width    : 611px !important; */
    width    : 35vw;
    min-width: 335px;
    max-width: unset !important;
}

.Mask {
    color: rgba(0, 0, 0, 0.5) !important;
}

.Pointed {
    fill: rgba(255, 255, 255, 0.16) !important;
}