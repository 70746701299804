.Container {
    display       : flex;
    flex-direction: column;

    align-items: center;

    margin: 5vh 10px;
}

.ActionContainer {
    margin: 20px 0;
}

.Item {
    font-size: var(--font-20);

    margin: 10px 0;
}

.TextContainer {
    width: 55vw;

    text-align: center;

    color: var(--white);

    font-size: var(--font-21);
}

.Note {
    font-size: var(--font-19);

    margin: 10px 0;

    color: var(--red);
}

.Red {
    color: var(--red);
}