.Wrapper {
    background-image: url("../../assets/img/img_guide_bg.png");
    background-size : cover;

    width : 100%;
    height: 100%;

    color: var(--main-text);

    background-color: black;
}

.Content {
    display: flex;

    flex-wrap      : wrap;
    justify-content: center;

    height    : calc(100vh - 390px);
    overflow-y: auto;

    scroll-margin: 50px 0;

    padding-bottom: 200px;
}

.Content>div {
    margin: 15px 6px;

    position: relative;
}

.Content>div:last-of-type {
    /* margin-bottom: 50vh; */
}

.Opacity {
    position: absolute;
    top     : 0;
    left    : 0;

    width : calc(100% - 10px);
    height: calc(100% - 10px);

    margin: 5px;

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));

    display        : flex;
    justify-content: center;
    align-items    : center;
}