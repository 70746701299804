.Sort {
	height: 100vh;
	width : fit-content;

	padding: 10px 30px;

	background: var(--09-vucos-grey);

	display        : flex;
	flex-direction : column;
	justify-content: center;
	align-items    : center;

	position: relative;
}

/* FILTER */
.Filter {
	height    : 100vh;
	position  : relative;
	background: var(--09-vucos-grey);


	position: relative;

	width    : 65vw;
	max-width: 950px;
}

.ImgContainer {
	height: 35vh;
}

.ImgContainer img {
	height    : 100%;
	width     : 100%;
	object-fit: cover;
}

.FilterContainer {
	width    : 100%;
	max-width: fit-content;

	display        : flex;
	flex-direction : column;
	justify-content: center;

	overflow-y: auto;

}

.FilterContainer>* {
	margin    : 20px;
	margin-top: 0;
}

.FilterContainer>*:last-child {
	margin-bottom: 0;
}

.FilterContent {
	position: absolute;
	top     : 0;

	height        : 100vh;
	padding-bottom: 90px;
	/*action container*/

	margin    : 0 auto;
	text-align: center;

	display        : flex;
	flex-direction : column;
	align-items    : center;
	justify-content: center;
}

.FilterTitle {
	font-weight: 600;
	font-size  : var(--font-19);
	line-height: var(--line-120);

	text-align   : left;
	color        : var(--main-text);
	margin-bottom: 10px;
}

.RadioItem {
	width: 100%;
}

.RadioItem>p {
	max-width : unset !important;
	text-align: left;

	font-size: var(--font-18);
}

.RadioItem>div {
	margin-left: 10px;
}

.SmartSign,
.YearFilter {
	padding        : 5px;
	display        : flex;
	align-items    : center;
	justify-content: center;
}

.YearFilter {
	justify-content: space-between;
}

.YearFilter>p {
	font-weight: 500;
	font-size  : var(--font-18);
	line-height: var(--line-120);

	color    : var(--main-text);
	max-width: 190px;
}

.SmartSign>img {
	height      : 30px;
	width       : auto;
	margin-right: 8px;
}

.YearFilter>p {
	margin-right: 8px;

	white-space: nowrap;
}

.SignContent,
.YearContent {
	display  : flex;
	flex-wrap: wrap;

	overflow-y: auto;

	padding-bottom: 10px;
	margin-bottom : 10px;
}

.SignContent>* {
	margin-right: 8px;
}

.ActionContainer {
	position: absolute;
	bottom  : 30px;
	z-index : 2;

	background: var(--09-vucos-grey);

	display        : flex;
	justify-content: center;

	width: 100%;
}

.ActionContainer>* {
	margin: 10px 20px;

	height: 40px;

	width    : 30%;
	min-width: fit-content;
	max-width: 200px;

	color: var(--main-text);

}