.Item {
    flex-shrink : 0;
    margin-right: 2px;
    position    : relative;

    cursor: pointer;

    --movie-width : calc(var(--movie-w) * 0.55);
    --movie-height: calc(var(--movie-h) * 0.55);
}

.Item.LastItem {
    padding-right: calc(100% - var(--movie-width) - 5px) !important;
}

.FocusPoint {
    height  : 40px;
    position: absolute;
    top     : -53px;
    width   : 100%;
}

.Wrapper {
    width   : var(--movie-width);
    height  : var(--movie-height);
    position: relative;
    padding : 0px;
    border  : 5px solid transparent;

    border-radius: 4px;
}

.Focused .Wrapper {
    border-color: var(--main-color);
}

.ImgContainer {
    width            : 100%;
    height           : 100%;
    background-size  : cover;
    background-repeat: no-repeat;
    border-radius    : 4px;
    background-color : rgb(43 43 49 / 35%);
    display          : flex;
    align-items      : center;
    justify-content  : center;
}

.TopItem.Focused .ImgContainer {
    /* .TopItem:hover .ImgContainer  */
    height: 72%;
}


/* ContinueToWatch */
.ContinueItem {
    --continue-width: calc(var(--channel-w) * 0.85);

}

.ContinueItem .Wrapper {
    width : var(--continue-width);
    height: calc(var(--channel-h) * 0.8);
}

.LastItem.ContinueItem {
    padding-right: calc(100% - var(--continue-width)) !important;
}

.ContinueInfo {
    position      : absolute;
    bottom        : 15px;
    left          : 3%;
    width         : 94%;
    display       : flex;
    flex-direction: column;
}

.ContinueDuration {
    width           : fit-content;
    color           : var(--main-text);
    background-color: hsla(240, 7%, 18%, 0.8);
    align-self      : flex-end;
    font-weight     : 400;
    font-size       : var(--font-18);
    line-height     : 120%;
    padding         : 5px 5px;
    margin-bottom   : 2px;
}

.ContinueLine {
    width     : 100%;
    height    : 4px;
    background: var(--08-vucos-grey);
}

.WatchedLine {
    height    : 100%;
    max-width : 100%;
    background: var(--main-color);
}

.ContinueTitle {
    width      : calc(0.9 * var(--continue-width));
    margin-top : 10px;
    font-weight: 400;
    font-size  : var(--font-20);
    line-height: 120%;

    text-align        : center;
    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow          : hidden;

    color: var(--main-text);

    color: var(--03-1-vucos-grey);
}

.Focused .ContinueTitle {
    /* .ContinueTitle:hover  */
    color: var(--02-vucos-grey);
}

/* ContinueToWatch ends....*/

/* Top10 */
.TopItem .Wrapper {
    width : var(--movie-width);
    height: var(--movie-height);
}

.LastItem.TopItem {
    padding-right: calc(100% - var(--movie-width)) !important;
}

.TopItem .TopPickText {
    position                 : absolute;
    bottom                   : 15px;
    right                    : 5%;
    height                   : 135px;
    font-size                : var(--font-28);
    line-height              : 120%;
    font-weight              : 700;
    text-align               : right;
    color                    : rgba(0, 0, 0, 0.5);
    -webkit-text-stroke-color: var(--04-vucos-grey);
    -webkit-text-stroke-width: 2px;
    -webkit-text-fill-color  : rgba(0, 0, 0, 0.5);
}

.TopItem .TopDetail,
.TopItem.Focused .TopPickText {
    /* .TopItem:hover .TopPickText { */
    display: none;
}

.TopItem.Focused .TopDetail {
    /* .TopItem:hover .TopDetail  */
    display         : block;
    background-color: #000000;
    width           : 100%;
    height          : 28%;
    display         : flex;
    flex-direction  : column;
    justify-content : space-around;
    align-items     : center;
}

.TopDetail .Title {
    margin-bottom: 0;
    width        : 95%;
}

.Tag {
    font-weight       : 500;
    font-size         : var(--font-18);
    line-height       : 120%;
    /* 04-vucos-grey */
    color             : var(--04-vucos-grey);
    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-align        : center;
    width             : 95%;
}

/* Top10 ends.... */

.Detail {
    position       : absolute;
    bottom         : 0;
    left           : 0;
    width          : 100%;
    height         : 100%;
    padding-bottom : 27px;
    display        : flex;
    flex-direction : column;
    justify-content: end;
    align-items    : center;
    background     : linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 91.79%)
}

.Title {
    position: absolute;
    bottom  : 10px;

    width: 80%;

    font-weight: 400;
    font-size  : var(--font-19);
    /* line-height: 120%; */
    color      : var(--main-text);

    margin            : 0 10% 15%;
    text-align        : center;
    display           : -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow          : hidden;
}

.InfoRow {
    display    : flex;
    align-items: center;
}

.Year {
    /* 04-vucos-grey */
    border       : 1px solid var(--04-vucos-grey);
    border-radius: 4px;
    padding      : 2px 10px;
    margin-right : 12px;
    font-weight  : 400;
    font-size    : var(--font-20);
    line-height  : 120%;
    /* 04-vucos-grey */
    color        : var(--04-vucos-grey);
}

.Length {
    font-weight: 400;
    font-size  : var(--font-18);
    line-height: 120%;
    /* 04-vucos-grey */
    color      : var(--04-vucos-grey);
}