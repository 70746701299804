.Container {
    height: 100vh;

    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
    color          : var(--main-text);

    width : 80%;
    margin: 0 auto;
}

.Header {
    font-weight: 700;
    font-size: var(--font-35);
    line-height: var(--line-125);

    margin-bottom: 21px;
}

.Text {
    font-weight: 400;
    font-size: var(--font-25);
    line-height: var(--line-125);
}