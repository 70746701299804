.Container {

    /* padding: 48px 40px 24px 40px; */
    padding: 20px 30px;

    background: linear-gradient(90deg, var(--09-vucos-grey) 0%, var(--11-vucos-grey) 100%);
}

.Wrapper>* {
    margin: 0 10px 5px;
}

.Wrapper {
    width: fit-content;

    display: flex;

    /* overflow-x: auto; */
}