.HeaderSection {
    display  : flex;
    flex-wrap: wrap;
}

.ContentSection {
    margin-top: 30px;

    max-height: calc(100vh - 208px - 35px);
    overflow-y: auto;
    /* min-height: 60vh; */

    /* padding-bottom: 100%; */

    display       : flex;
    flex-wrap     : wrap;
    /* align-items: stretch; */
}