.Container {
    padding-left : 50px;
    padding-top  : 105px;
    padding-right: 50px;
}

.Content {
    margin-top: 30px;
}

.Content.Disabled {
    opacity: 0.5;
}

.LoadingContainer {
    position: relative;

    width: 100%;
}

.Title {
    font-size  : var(--font-23);
    line-height: var(--line-120);
    font-weight: 400;
    text-align : left;
}

.Subtitle {
    font-size  : var(--font-21);
    line-height: var(--line-100);
    font-weight: 400;
    text-align : left;

    color: var(--04-1-vucos-grey);

    margin-top   : 15px;
    margin-bottom: 30px;
}

.Content>* {
    width: 90%;

    padding-left: 30px;
}

.SelectItem {
    display        : flex;
    justify-content: space-between;

    padding     : 20px 15px;
    padding-left: 30px;

    background: var(--08-vucos-grey);

    border-radius: 8px;

    font-size  : var(--font-19);
    font-weight: 400;
    line-height: var(--line-120);

    color: var(--main-text);
}

.Button {
    border: none;
}

.Button>div {
    display    : flex;
    align-items: center;

    position: relative;
}

.Button svg {
    margin-left : 20px;
    margin-right: 0px;
}

.FocusedButton {
    border: none;

    color: var(--main-color);
}

.Button img {
    max-height: 80px;
    width     : auto;

    position: absolute;
    right   : 52px;
}

.ActionContainer {
    margin-top: 40px;
}

.ActionContainer>* {
    margin-right: 25px;
    height      : 50px;
    min-width   : 200px;
}