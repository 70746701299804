.Container {
    padding: 20px 30px;

    background: linear-gradient(90deg, var(--09-vucos-grey) 0%, var(--11-vucos-grey) 100%);

}

.Wrapper {
    display: flex;
    width: fit-content;

    /* overflow-x: auto; */
}

.Wrapper>* {
    margin: 0 5px 5px;

    color: white !important;
}