.Container {
    width : fit-content;
    height: 100%;

    position: absolute;
    top     : 0;
    left    : calc(100% + 43px);

    display       : flex;
    flex-direction: column;

    color: var(--main-text);
}


.Content {
    width : 100%;
    height: 60%;

    margin-top: 10vh;

    max-height: 56vh;
    overflow-y: auto;

    display       : flex;
    flex-direction: column;

    
}

.Link {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 120%;

    padding: 12px 27px;

    margin-bottom: 20px;
    cursor: pointer;
}

.Focused {}