.Item {
    border       : 3px solid transparent;
    border-radius: 4px;
}

.Focused,
.Item:hover {
    border-color: var(--main-color);
    cursor      : pointer;
}

.Item.SvgItem {
    border: none;
}

.Item.SvgItem>svg {
    color: #8F8F90;
}

.Focused.SvgItem>svg,
.Item.SvgItem>svg:focus,
.Item.SvgItem>svg:hover {
    color: var(--main-color);
}