.Container {
	margin-bottom: 40px;
	margin-left  : 80px;
	position     : relative;
}

.List {
	display        : flex;
	flex-direction : row;
	overflow-x     : auto;
	overflow-y     : hidden;
	scroll-behavior: smooth;
}

.ListHeader {
	display    : flex;
	align-items: center;
}

.ListHeader>* {
	margin-bottom: 13px;
}

.ListHeader>.ListTitle {
	margin-right: 10px;
}

.ListTitle {
	font-style   : normal;
	font-weight  : 400;
	font-size    : var(--font-25);
	line-height  : 120%;
	color        : var(--main-text);
	margin-bottom: 13px;
}

.List>div:last-of-type {}

.ListArrowLeft {
	position        : absolute;
	left            : 0;
	top             : 46px;
	bottom          : 5px;
	width           : 40px;
	display         : flex;
	align-items     : center;
	justify-content : center;
	background-color: rgba(0, 0, 0, 0.2);
	color           : gainsboro;
	cursor          : pointer;
	user-select     : none;
}

.ListArrowLeft:hover {
	background-color: rgba(0, 0, 0, 0.5);
}

.ListArrowRight {
	position        : absolute;
	right           : 0;
	top             : 46px;
	bottom          : 5px;
	width           : 40px;
	display         : flex;
	align-items     : center;
	justify-content : center;
	background-color: rgba(0, 0, 0, 0.2);
	color           : gainsboro;
	cursor          : pointer;
	user-select     : none;
}

.ListArrowRight:hover {
	background-color: rgba(0, 0, 0, 0.5);
}

.REPLAYS {
	width : calc(var(--channel-w) * 0.85);
	height: calc(var(--channel-h) * 0.85);

}

.REPLAYSItem {
	padding-right: calc(100% - var(--channel-w) * 0.85 - 5px) !important
}