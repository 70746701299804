.Container {
    display        : flex;
    justify-content: space-between;
    align-items    : center;

    height: 50px;

    margin-bottom: 14px;
}

.Item {
    display    : flex;
    align-items: center;


    padding-left: 20px;
    width       : 100%;
    height      : 100%;

    border-radius: 8px;
}

.Focused .Item,
.Item:hover {
    background: linear-gradient(89.98deg, var(--07-vucos-grey) 46.35%, rgba(64, 64, 69, 0) 99.99%);
}

.Date {
    font-weight: 400;
    font-size  : var(--font-19);
    line-height: var(--line-145);

    color: var(--04-vucos-grey);

    margin-right: 20px;
}

.TextContainer {
    display    : flex;
    align-items: center;

    flex: 1 1 0;
}

.Title,
.Detail {
    font-weight: 700;
    font-size  : var(--font-20);
    line-height: var(--line-120);

    color: var(--02-vucos-grey);
}

.Detail {
    font-weight: 400;
    color      : var(--04-vucos-grey);
}

.ActionContainer {
    min-width: fit-content;
}

.ActionContainer>* {
    margin-right: 10px;
}

/* .ActionContainer>*:last-child {
    margin-right: 0;
} */


.Button {
    font-weight: 400;
    font-size  : var(--font-19);
    line-height: var(--line-120);

    color: var(--02-vucos-grey);

    height: 50px;

    padding-left : 15px;
    padding-right: 15px;

    opacity: 0;
}

.Focused .Button,
.Container:hover .Button {
    /* background   : var(--main-color);
    border       : 1px solid var(--main-color);
    border-radius: 8px;

    color: var(--main-text); */

    opacity: 1;
}

.Container:has(.Button:hover) {
    background: linear-gradient(89.98deg, var(--07-vucos-grey) 46.35%, rgba(64, 64, 69, 0) 99.99%);
}