.Link {
    font-size: var(--font-24);
    line-height: 120%;

    color: var(--03-vucos-grey);

    text-decoration: none;

    padding: 10px 12px;
}

.Link.Focused {
    /* background-color: var(--main-color); */
    /* color           : var(--09-vucos-grey); */
    color           : var(--main-color);
    /* border-radius: 8px; */
}

.Link:hover {
    /* background-color: var(--main-color); */
    /* color           : var(--09-vucos-grey); */
    color           : var(--main-color) !important;
    /* border-radius: 8px; */
}