.Container {
    height: 100vh;

    overflow: hidden;
}

.TextContainer {
    height: 50vh;

    background-repeat: no-repeat;
    background-size  : cover;

    width: 100%;

    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
}

.TextContainer>* {
    width: 80%;

    text-align: center;
}

.Title {
    font-weight: 700;
    font-size  : var(--font-25);
    line-height: 125%;

    color: var(--02-vucos-grey);

    margin-bottom: 20px;
}

.Text {
    font-weight: 400;
    font-size  : var(--font-19);
    line-height: 125%;

    color: var(--03-vucos-grey);
}

.ContentSection {
    padding     : 0;
    padding-left: 3vw;

    height: 50vh;

    position: relative;
}

.ListHeader {
    font-weight: 400;
    font-size  : var(--font-21);
    line-height: var(--line-120);

    text-align: left;

    color: var(--main-text);

    position: absolute;
    top     : -40px;
}

.ContentList {
    height: 100%;
    
    display  : flex;
    flex-wrap: wrap;

    overflow-y: auto;

    padding-bottom: 20%;
}

.ContentList>div {
    margin-right : 5px;
    margin-bottom: 5px;
}