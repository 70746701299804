.Navbar {
    background: linear-gradient(90deg, var(--09-vucos-grey) 0%, var(--11-vucos-grey) 100%);

    padding: 0px 5vw;
}

.List {
    list-style: none;

    display        : flex;
    flex-direction : column;
    height         : 100vh;
    justify-content: space-evenly;
}

.List li {
    margin-bottom: 5px;
}

.Content.ScrollVertical {
    max-height: calc(50px * 3 + 44px);
    overflow-y: auto;
}

.Content.ScrollVertical>*:last-child {
    margin-bottom: 50px;
}

.SectionTitle {
    font-style : normal;
    font-weight: 400;
    font-size  : var(--font-20);
    line-height: 120%;

    color: var(--04-1-vucos-grey);

    margin-bottom: 10px;
    padding-left : 23px;
}

.NavLink {
    font-weight: 400;
    font-size  : var(--font-17);
    line-height: var(--line-120);

    color: var(--main-text);

    padding      : 10px 23px;
    padding-right: 13px;

    border       : 1px solid transparent;
    border-radius: 8px;

    text-decoration: none;

    display: block;
}


.Focus,
.NavLink:hover {
    background  : var(--main-color);
    color       : var(--main-text);
    border-color: var(--main-color);
}


.Active {
    background: var(--07-vucos-grey);
}

.Bold {
    color      : var(--main-color);
    font-weight: bold;
}

@media(max-height: 900px) {
    .Content.ScrollVertical {
        max-height: 100px;
    }

}