.Container {
    display        : flex;
    flex-direction : column;
    justify-content: start;
    align-items    : center;

    height: 100vh;

    color: var(--main-text);

    padding-top: 63px;

}

.Account {
    display       : flex;
    flex-direction: column;
    align-items   : center;

    color: var(--main-text);

    overflow-y: auto;
    height    : 100vh;

    padding: 50px 0;
}

.HeaderContainer {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;

    position: relative;

    width: 100%;
}

.Back {
    position: absolute;
    top     : 7px;
    left    : 63px;
}

.HeaderContainer>svg {
    margin-bottom: 40px;
}

.Header,
.SubsHeader h1 {
    font-weight: 700;
    font-size  : var(--font-35);
    line-height: 120%;

    text-align: center;
}

.SubsHeader {
    display        : flex;
    justify-content: space-between;
    align-items    : center;

    margin-bottom: 22px;
}

.SubsHeader h1 {
    text-align: left;
}

.Greeting {
    margin-bottom: 11px;

    font-size  : var(--font-25);
    font-weight: 400;
    line-height: var(--line-120);
    text-align : right;
}

.ClientNo {
    font-size  : 19px;
    font-weight: 400;
    line-height: 120%;

    color: var(--04-1-vucos-grey);

    text-align: right;
}

.ClientNo>span {
    font-size: var(--font-22);
}

.HeaderSection {
    margin-top   : 50px;
    margin-bottom: 70px;
}

.SubscriberSection {
    max-width: 1160px;
    width    : 90%;
}

.FormSection,
.HeaderSection,
.TextSection {
    width: 90%;

    display        : flex;
    justify-content: center;
}

.FormSection>div,
.TextSection>* {
    width    : 100%;
    max-width: 500px;
}

.TextSection {
    margin-top: 30px;

    color: var(--02-vucos-grey);

    font-size  : 17px;
    line-height: 120%;

    text-align: center;
}

.Link {
    color: var(--main-color);

    text-decoration: none;
}

.Link:hover {
    text-decoration: underline;
}

@media(max-height: 800px) {
    .Container {
        padding-top: 40px;
    }

    .HeaderContainer>svg {
        margin-bottom: 30px;

        height: 90px;
    }

    .HeaderSection {
        margin-top   : 30px;
        margin-bottom: 40px;
    }
}