.HeaderContainer {
    display        : flex;
    justify-content: space-between;
    align-items    : center;

    font-family: 'Montserrat';
    font-style : normal;

    padding-left : 37px;
    margin-bottom: 9px;
}

.HeaderContainer.Focused {
    background   : linear-gradient(89.98deg, var(--07-vucos-grey) 46.35%, rgba(64, 64, 69, 0) 99.99%);
    border-radius: 8px;
}

.Header {
    font-weight: 700;
    font-size  : var(--font-22);
    line-height: var(--line-120);

    color: var(--02-vucos-grey);
}

.Button {
    font-weight: 400;
    font-size  : var(--font-19);
    line-height: var(--line-120);

    color: var(--02-vucos-grey);

    height: 50px;

    padding-left : 15px;
    padding-right: 15px;
}

.Button.Focused {
    background   : var(--main-color);
    border       : 1px solid var(--main-color);
    border-radius: 8px;

    color: var(--main-text);
}

.List {
    overflow-y: auto;

    /* padding-bottom: 20vh; */

}

.List>*:last-of-type {
    /* margin-bottom: 50%; */
}