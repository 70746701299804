.Wrapper {
	height     : 100vh;
	padding-top: 115px;

	display        : flex;
	flex-direction : column;
	justify-content: start;

	background-color : var(--09-vucos-grey);
	color            : var(--main-color);
	background-image : url("../../assets/img/img-bg.png");
	background-repeat: no-repeat;
	background-size  : cover;
}

.Container {
	/* height: 70%; */

	display        : flex;
	flex-direction : column;
	justify-content: center;

	z-index: 1;
}

.HeaderContainer {
	margin-bottom: 50px;

	display        : flex;
	flex-direction : column;
	justify-content: center;
	align-items    : center;
}

.HeaderContainer img {
	height    : 150px;
	max-width : 180px;
	object-fit: cover;
}

.Header {
	font-weight: bold;
	font-size  : var(--font-45);
	line-height: var(--line-200);

	text-align: center;

	color: var(--main-text);
}

.Content {
	align-self  : center;
	justify-self: center;
	display     : flex;
	position    : relative;
	z-index     : 1;
	overflow-x  : auto;
	max-width   : 85%;
}

.Content::-webkit-scrollbar {
	height: 0px;
	width : 0px;
}

.Content>div {
	margin-right: 50px;
}

.Content>div:last-child {
	margin-right: 0;
}