.Container {
    color     : var(--main-text);
    background: var(--09-vucos-grey);

    height: 100vh;

    display       : flex;
    flex-direction: column;
    align-items   : center;

    padding-top: 10vh;
}

.Header {
    font-size  : var(--font-24);
    font-weight: 500;
    line-height: var(--line-200);

    margin: 0 auto 8vh;

    width: 70%;

    text-align: center;

}

.NumberContainer {
    display: flex;
    justify-content: center;

    margin-bottom: 8vh;
}

.NumberContainer>* {
    margin: 0 15px;
}

.ActionContainer>* {
    margin: 0 15px;
}

.InfoContainer,
.ActionContainer {
    margin-top: 30px;

    display        : flex;
    justify-content: center;
}

.InfoContainer button {
    border-color: transparent;
}

.Input input {
    font-weight: 300;
    font-size  : var(--font-30);
    line-height: var(--line-120);

    color: var(--main-text);

    text-align: center;

    width : 72px;
    height: 80px;

    padding: 0;
    margin : 0;
}

.ModalText {
    font-size  : var(--font-20);
    font-weight: 400;
    line-height: var(--line-145);
    text-align : center;

    color: var(--04-1-vucos-grey);

    margin-bottom: 30px;
}

.ModalText>span {
    color: var(--main-color);
}