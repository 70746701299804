.Title {
    font-size  : var(--font-23);
    font-weight: 400;
    line-height: var(--line-120);
    text-align : left;

    color: var(--main-text);

}

.Content {
    margin-top: 30px;

    position: relative;
    /* left    : -25px; */

    display        : flex;
    flex-direction : column;
    justify-content: center;
}

.TextContainer {
    display       : flex;
    flex-direction: column;
    align-items   : center;

    margin-bottom: 25px;
}

.Subheader {
    font-size  : var(--font-22);
    font-weight: 400;
    line-height: var(--line-200);
    text-align : left;

    color: var(--main-text);
}

.Text {
    font-size  : var(--font-21);
    font-weight: 400;
    line-height: var(--line-200);
    text-align : left;

    color: var(--04-1-vucos-grey);
}

.ItemContainer {
    display        : flex;
    flex-direction : column;
    justify-content: flex-start;

    width: fit-content;

    margin: 0 auto;

    height         : 45vh;
    overflow-y     : auto;
    justify-content: flex-start;
}


.NumberContainer {
    display        : flex;
    justify-content: flex-start;
    align-items    : center;

    margin-top   : 30px;
    margin-bottom: 30px;

    /* position: relative; */
}

/* .NumberContainer>* * {
    margin: 0 15px;
} */

.Item,
.SubItem {
    display        : flex;
    justify-content: center;
    align-items    : center;

}

.Input,
.EmptyInput,
.Sign {
    margin: 0 10px;
}


.Input input,
.EmptyInput input {
    font-weight: 300;
    font-size  : var(--font-30);
    line-height: var(--line-120);

    color: var(--main-text);

    text-align: center;

    width : calc(72px * 0.7);
    height: calc(80px * 0.7);

    padding: 0;
    margin : 0;
}

.Input input::placeholder {
    color: var(--main-text);
}

.Sign {
    font-weight: bold;
    font-size  : var(--font-20);
    line-height: var(--line-120);

    color: var(--main-text);
}

.ActionContainer {
    height: 100%;

    display    : flex;
    align-items: center;

    margin-left: 20px;
}

.Button {
    font-size: var(--font-20);

    margin-right: 20px;

    min-width: 150px;

    height: 50px;
}