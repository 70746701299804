.Item {
    display    : flex;
    align-items: center;

    font-size  : var(--font-20);
    font-weight: 400;
    line-height: var(--line-120);

    padding      : 10px 25px 10px 38px;
    height       : 70px;
    border-radius: 8px;

    color: var(--main-text);

    text-align: left;

    background-color: #202027;

    border-width: 2px;

    margin-bottom: 21px;
}

.Focused {
    border-width: 2px;
}

.LinkContent {
    display        : flex;
    justify-content: space-between;
    align-items    : center;

    width: 100%;
}

.Item:not(.Focused) .LinkContent svg {
    color: transparent;
}