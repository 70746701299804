.Container {
    margin-bottom: 15px;

    font-weight  : 500;
    font-size    : var(--font-19);
    line-height  : 120%;
    color: var(--main-text);
}

.Header {
    font-weight: 500;
    font-size  : var(--font-21);
    line-height: 120%;

    color: var(--04-1-vucos-grey);

    margin-bottom: 20px;

    flex: none;
}

.ItemContainer {
    display       : flex;
    flex-direction: column;

    width: max-content;
}

.Button {
    font-weight: 500;
    font-size  : var(--font-25);
    line-height: var(--line-120);

    height   : 71px;
    min-width: fit-content;
    max-width: fit-content;

    padding: 0 20px;

    border: none;

    color: var(--main-text);

}

.Focused.Button {
    background   : var(--main-color);
    border-radius: 8px;
}

.ItemContainer>button:last-of-type {
    margin-right: 80%;
}