body {
    --main-color: #00A6FF;
    --main-text : #FFFFFF;
    --dark-color: #000000;

    --none: transparent;

    --red: #CE1720;

    --02-vucos-grey  : #BDBDBD;
    --03-vucos-grey  : #A8A8A9;
    --03-1-vucos-grey: #C4C4C4;
    --04-vucos-grey  : #949394;
    --04-1-vucos-grey: #969696;
    --05-vucos-grey  : #6A6A6C;
    --06-vucos-grey  : #555558;
    --07-vucos-grey  : #404045;
    --08-vucos-grey  : #2B2B31;
    --09-vucos-grey  : #16161D;
    --10-vucos-grey  : #1D1D26;
    --11-vucos-grey  : #202027;

    --loading_bgcolor: rgba(0, 0, 0, 0.8);
}