.Section {
    display       : flex;
    flex-direction: column;
    align-items   : center;

    padding-top  : 152px;
    margin-bottom: 50px;

    font-family: 'Montserrat';
    font-style : normal;
}

.Section img {}

.MainText {
    font-weight: 700;
    font-size: var(--font-33);
    line-height: 120%;

    color: var(--main-text);

    margin: 60px 0 20px;
}

.AltText {
    font-weight: 400;
    font-size: var(--font-22);
    line-height: 145%;

    color: var(--05-vucos-grey);
}