body {
    --movie-w: 355px;
    --movie-h: 500px;

    --channel-w: 362px;
    --channel-h: 203px;
}  

@media(max-height: 800px) {
    body {
        --movie-w: calc(355px * 0.8);
        --movie-h: calc(500px * 0.8);

        --channel-w: calc(362px * 0.8);
        --channel-h: calc(203px * 0.8);
    }
}