.Container {
    display: flex;

    padding: 30px 65px;

    background: linear-gradient(90deg, var(--09-vucos-grey) 0%, var(--11-vucos-grey) 100%);
}

.ActionList {
    width    : 26%;
    max-width: 500px;
    min-width: fit-content;

    display       : flex;
    flex-direction: column;

    margin-right: 20px;
}

.Link,
.ActiveLink,
.FocusLink {
    border: none !important;

    display    : flex !important;
    align-items: center !important;

    height: 50px !important;
    width : 100% !important;
    max-width: 240px;
    min-width: fit-content;

    margin-bottom: 12px !important;
    padding      : 0 22px 0 22px !important;

}

.Link svg,
.ActiveLink svg,
.FocusLink svg {
    margin-right: 10px !important;

    height: 40%;
    width : auto;
}

.Links a:last-of-type {
    margin-bottom: 0 !important;
}

.Link,
.ActiveLink,
.FocusLink {
    font-style  : normal !important;
    font-weight : 400 !important;
    /* font-size: var(--font-30) !important; */
    line-height : var(--line-120) !important;

    text-decoration: none !important;
}

/* 
.Link,
.Link svg, */
.Link {
    /* color: #969696 !important;
    fill : #969696 !important; */
    color: var(--main-text) !important;
    fill : var(--main-text) !important;
}

.FocusLink,
.ActiveLink {
    color: var(--main-text) !important;
    fill : var(--main-text) !important;

    background-color: var(--main-color) !important;
    border-radius   : 8px !important;
}