.Item {
    flex-shrink : 0;
    margin-right: 2px;

    cursor: pointer;
}

.Item.LastItem {
    /* margin-right: calc(100% - 865px) !important; */
    padding-right: calc(100% - 865px) !important;
}

.Wrapper {
    width        : 865px;
    height       : 300px;
    position     : relative;
    padding      : 5px;
    border       : none;
    border-radius: 4px;
}

.Focused .Wrapper{
/* .Wrapper:hover  */
    padding: 0;
    border : 5px solid var(--main-color);
}

.ImgContainer {
    width            : 100%;
    height           : 100%;
    background-size  : cover;
    background-repeat: no-repeat;
    border-radius    : 4px;
    position         : relative;
}