.Container {
    color: var(--main-text);

    height: 100vh;

    display : flex;
    position: relative;

    overflow: hidden;
}

.Container>nav {
    min-width: fit-content;
    max-width: fit-content;

    height    : 100vh;
    overflow-y: hidden;
}

.Section {
    background: var(--09-vucos-grey);

    height: 100vh;
    width : 100%;

    flex    : 1 1 0;
    overflow: hidden;

    position: relative;

    background-image   : url("../../assets/img/img-bg.png");
    background-position: center;
    background-size    : cover;
}