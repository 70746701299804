.Container {
    width : 150px;
    height: 150px;

    border-radius: 50%;

    position: relative;

    z-index: 1;

    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;

    color: var(--main-text);
}

.Container::before {
    width : 150px;
    height: 150px;

    border-radius: 50%;

    background-color: var(--main-color);

    opacity: 0.3;

    content : "";
    position: absolute;
    top     : 0;
    left    : 0;

    z-index: -1;
}

.Container p {
    margin-top: 10px;

    font-size: 15px;

    opacity: 0.8;
}

.Left,
.Right {
    position: fixed;

    top: 35vh;
}

.Left {
    left: 5vw;
}

.Right {
    right: 5vw;
}

.ArrowContainer {
    display        : flex;
    align-items    : center;
    justify-content: center;
}