.Container {
    padding-left: 50px;
    padding-top : 105px;
}

.LoadingContainer {
    position: relative;

    width: 100%;
}

.Table {
    /* width: 944px; */
}

.Table tr {
    height        : 60px;
    vertical-align: center;
}

.Table th,
.Table td {
    padding-bottom: 25px;

}

.Table th {
    width: 180px;
    min-width: fit-content;
    white-space: nowrap;

    font-weight: 700;
    font-size: var(--font-19);
    line-height: var(--line-120);

    color: var(--06-vucos-grey);

    text-align: left;

    padding-right: 10px;
}

.Table td {
    font-weight: 400;
    font-size: var(--font-19);
    line-height: var(--line-120);

    color: var(--03-vucos-grey);

}

.Table td:first-of-type {
    padding-right: 50px;
}

.Button {
    font-weight: 400;
    font-size: var(--font-17);
    line-height: var(--line-120);

    color: var(--02-vucos-grey);

    height: 50px;
}

.Button.Focused {
    background   : var(--main-color);
    border       : 1px solid var(--main-color);
    border-radius: 8px;

    color: var(--main-text);
}

tr.Space {
    height: 50px;
}

.Dot {
    display: inline-block;

    width        : 10px;
    height       : 10px;
    border-radius: 50%;

    background: var(--04-1-vucos-grey);

    margin-right: 7px;
}