.Container {
    position: relative;

    animation-name    : frombelow;
    animation-duration: 0.5s;
    animation-timing-function: ease-in;
}

@keyframes frombelow {
    0% {
        bottom : -100px;
        opacity: 0;
    }

    50% {
        bottom : -50px;
        opacity: 0.5;
    }

    100% {
        bottom : 0px;
        opacity: 1;
    }
}