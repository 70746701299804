.Links {
    display       : flex;
    flex-direction: column;
}

.Link,
.ActiveLink,
.FocusLink {
    display: inline-block;
    border : none;

    display    : flex;
    align-items: center;

    height     : 50px;
    /* width   : fit-content; */
    width      : 100%;
    max-width  : 300px;
    min-width  : fit-content;

    white-space: nowrap;

    /* margin-bottom: 12px; */
    margin-bottom: 10px;
    padding      : 0 12px 0 12px;
}

.Link svg,
.ActiveLink svg,
.FocusLink svg {
    margin-right: 10px;

    /* width: 20%; */
    /* height: 30%; */
    /* width: fit-content; */
    width    : 20px;
    min-width: 20px;
    height   : auto;

    max-height: 50%;
}

.Links a:last-of-type {
    margin-bottom: 0;
}

.Link,
.ActiveLink,
.FocusLink {
    font-style : normal;
    font-weight: 400;
    font-size  : 1.2rem;
    line-height: 120%;

    text-decoration: none;
}

.Link,
.Link svg {
    color: var(--02-vucos-grey);
    fill : var(--02-vucos-grey);
}

/* .ActiveLink {
    color: var(--02-vucos-grey);

    background   : var(--07-vucos-grey);
    border-radius: 8px;
}

.ActiveLink svg {
    fill: var(--02-vucos-grey);
} */

.FocusLink,
.FocusLink svg,
.ActiveLink,
.ActiveLink svg,
.Link:hover,
.Link:hover svg {
    color: var(--main-text);
    fill : white;
}

.FocusLink,
.ActiveLink,
.Link:hover {
    background-color: var(--main-color);
    border-radius   : 8px;
}

@media(max-height: 900px) {
    .Links {
        display              : grid;
        grid-template-columns: 1fr 1fr;
        column-gap           : 20px;
    }
}

@media(max-height: 700px) {
    .Links {
        display              : grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap           : 10px;
    }

    .Links>* {
        white-space: break-spaces;
        text-align : left;

        font-size: var(--font-18);
    }
}