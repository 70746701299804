.Container {
    display    : flex;
    align-items: center;
}

.Img {
    width     : 46px;
    height    : 46px;
    object-fit: cover;

    margin-right: 17px;
}

.Name {
    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 400;
    font-size: var(--font-30);
    line-height: 120%;

    color: var(--main-color);
}