.Section {
    width      : 100%;
    /* position: relative; */
    height     : 50vh;

    display    : flex;
    align-items: center;
}

.Placeholder {
    min-height: 50vh;
}

.ImgContainer {
    width : 70%;
    height: 65vh;

    background-repeat  : no-repeat !important;
    background-size    : cover !important;
    background-position: center 10% !important;

    position: absolute;
    top     : 0;
    right   : 0;

    overflow: hidden;
}

.PlaceholderImg {
    width : 100%;
    height: 65vh;

    background-repeat  : no-repeat !important;
    background-size    : cover !important;
    background-position: center 10% !important;

    position: absolute;
    top     : 0;
    right   : 0;

    overflow: hidden;
}

.Filter {
    position: absolute;
    top     : 0;
    right   : 0;

    width : 102%;
    height: 104%;

    background: linear-gradient(90deg, var(--09-vucos-grey) 6.41%, rgba(0, 0, 0, 0) 61.5%), linear-gradient(0deg, var(--09-vucos-grey) 0%, rgba(0, 0, 0, 0) 43.83%);
}

.VideoContainer {
    width : 70%;
    height: 70%;

    overflow: hidden;

    position: absolute;
    top     : 0;
    right   : 0;

}

.Content {
    position: relative;

    color: var(--main-text);

    width       : 80%;
    padding-left: 3vw;
}

.Type {
    font-weight: 400;
    font-size  : var(--font-21);
    line-height: 136%;

    color: var(--main-color);

    margin-bottom: 17px;
}

.ParentTitle {
    font-weight: bold;
    font-size  : var(--font-21);
    line-height: var(--line-136);

    color: var(--main-color);

    margin-bottom: 10px;

    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.Header {
    font-weight: 700;
    font-size  : var(--font-28);
    line-height: 120%;

    color: var(--main-text);

    margin-bottom: 17px;

    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.RentDetail {
    margin: 17px 0;

    display    : flex;
    align-items: center;

    flex-wrap: wrap;
}

.RentDetail>div {
    display    : flex;
    align-items: center;

    margin-right : 20px;
    margin-bottom: 5px;
}

.RentDetail h2 {
    font-size: var(--font-20);
}

.RentDetail p {
    font-weight: bold;
    font-size  : var(--font-19);
    color      : var(--main-color);

    margin-left: 5px;
}

.Detail {
    display    : flex;
    align-items: center;

    font-weight: 400;
    font-size  : var(--font-19);
    line-height: 136%;

    color: var(--03-vucos-grey);
}

.Detail>* {
    margin-right: 35px;
}

.Year {
    border       : 1px solid var(--04-vucos-grey);
    border-radius: 4px;

    padding: 2px 10px;
}

.Duration {

}

.RatingContainer {
    display    : flex;
    align-items: center;
}

.RatingContainer>img {
    width : 27px;
    height: 25px;

    margin-right: 10px;
}

.Rating {}

.Description {
    font-weight: 500;
    font-size  : var(--font-18);
    line-height: 125%;

    margin: 15px 0px 20px 0;

    display           : -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;

    color: white;
}