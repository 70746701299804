.Container {
    position: relative;

    margin-top  : 105px;
    margin-left : 50px;
    margin-right: 50px;

    height: calc(100vh - 105px);
}

.ProfileItem {
    position: fixed;
    right   : 50px;
    top     : 80px;

    display    : flex;
    align-items: center;
}

.ProfileItem img {
    width : 50px;
    height: 50px;

    border-radius: 50%;
}

.ProfileItem p {
    font-size  : var(--font-20);
    font-weight: 400;
    line-height: var(--line-100);
    text-align : center;

    margin-right: 10px;

    color: var(--main-color);
}

.ActionContainer {
    display: flex;

    position: absolute;
    left    : 0;
    bottom  : 68px;
}

.ActionContainer>* {
    margin-right: 25px;

    height: 50px;

    min-width: 200px;
}


.ParentalContainer,
.ChannelContainer,
.ListContainer {
    height: 100%;

    display       : flex;
    flex-direction: column;

    justify-content: space-between;
}

.ParentalContainer .ActionContainer,
.ChannelContainer .ActionContainer,
.ListContainer .ActionContainer {
    position: static;

    margin-top   : 20px;
    margin-bottom: 50px;
}

.ChannelContainer .AuthorizeBox {
    width: 80%;
}

.Title {
    font-size  : var(--font-22);
    font-weight: 400;
    line-height: var(--line-120);
    text-align : left;
}

.Content {
    margin-top: 40px;

    width: 100%;

    max-height: 60vh;
    overflow-y: auto;
}