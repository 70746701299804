.Title {
    font-size  : var(--font-23);
    font-weight: 400;
    line-height: var(--line-120);
    text-align : left;
}

.Content {
    margin-top: 30px;

    width: 100%;
}

.Content>* {
    width : 100%;
    height: 70px;

    margin-bottom: 15px;
    padding-left : 30px;
    padding-right: 20px;

    background-color: #202027;
}

.ButtonItem {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
}

.ButtonItem>p {
    font-size  : var(--font-20);
    font-weight: 400;
    line-height: var(--line-120);
    text-align : left;
}

.PinButtons {
    display    : flex;
    align-items: center;
}

.PinButtons>* {
    margin-right: 15px;
}

.PinButtons>*:last-child {
    margin-right: 0;
}

.PinButtons button {
    font-size  : var(--font-19);
    font-weight: 400;
    line-height: var(--line-120);
    text-align : center;

    /* min-width: 154px; */
    /* max-width: 154px; */

    padding: 10px;

    height: unset;

    margin-left: 11px;
}


.PinButtons button>div {
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.PinButtons button svg {
    margin-left: 10px;
}

.Disabled {
    opacity: 0.5;
}

.SwitchItem,
.SwitchItem:hover {
    border: none;
}