.Container {
    background: var(--09-vucos-grey);

    height: 100%;

    overflow: hidden;

    display       : flex;
    flex-direction: column;
}

.FullLoading {
    position: fixed;
    top     : 0;
    left    : 0;

    z-index: 1000;

    width : 100%;
    height: 100%;
}
