.Section {
    display      : flex;
    align-items  : center;
    background   : var(--09-vucos-grey);
    /* padding-left : 85px;
    padding-right: 80px;
    padding-top  : 117px; */

    height: 100vh;

    padding: 10vh 5vw 5vh;

    max-width: 90vw;

}

.Container {
    height  : 100%;
    overflow: hidden;
}

.HeaderContainer * {
    font-size: var(--font-24);

    margin-bottom: 5px;

    width: 250px;
    height: 60px;

    display: flex;
    align-items: center;
}

.EpisodeContainer {
    margin-left: 40px;
    height     : 100%;
    overflow-y : auto;
}