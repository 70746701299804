.Container {
    color: var(--main-text);

    height  : 100vh;
    width   : calc(100vw - 80px);
    overflow: hidden;

    display: flex;
}

.Container>nav {
    max-width: 350px;
    min-width: 350px;

    height    : 100vh;
    overflow-y: hidden;
}

.Section {
    background-image   : url("../../assets/img/img-bg.png");
    background-position: center;
    background-size    : cover;

    flex: 1 1 0;
}