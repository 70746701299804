.Section {
    width: 65%;

    margin: 0 auto;
}

.SearchInput {
    width: 100%;
}

.InputContainer {
    display       : flex;
    flex-direction: column;
    align-items   : flex-start;

    position: relative;
}

.SearchInput label {
    color: var(--02-vucos-grey);

    font-weight: var(--font-20);
}

.SearchInput input {
    /* border    : none; */

    background: var(--none);
    color     : var(--main-text);

    font-weight: 700;
    font-size  : var(--font-18);
    line-height: var(--line-200);

    height: 60px;

    margin-bottom: 20px;
}

.Note {
    font-weight: 300;
    font-size  : var(--font-17);
    line-height: var(--line-120);

    color: var(--02-vucos-grey);

}

.CompleteList.EmptyList {
    background-color: transparent;

    height    : 0;
    max-height: 0;
}

.CompleteList {
    position: absolute;

    height    : fit-content;
    max-height: 30vh;
    width     : 100%;

    overflow-y: auto;

    top: 90%;

    background-color: var(--08-vucos-grey);

    transition-property       : all;
    transition-duration       : 1s;
    transition-timing-function: ease;
    transition-delay          : 0.5s;

    border-radius: 8px;
}

.CompleteItem {
    width: 100%;

    padding: 15px;

    font-weight: 500;
    font-size  : var(--font-17);

    border-bottom-color: var(--05-vucos-grey);

    border-radius: 0;
}

.CompleteItem>p {
    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}