.Container {
    height  : 100vh;
    overflow: hidden;
    width : fit-content;

    display       : flex;
    flex-direction: column;
    flex-wrap     : wrap;
}

.Item {
    font-weight: 400;
    font-size  : var(--font-20);
    line-height: var(--line-80);

    color: var(--02-vucos-grey);

    width        : 300px;
    padding      : 20px 10px 20px 30px;
    margin-right : 40px;
    margin-bottom: 5px;

    border-radius: 8px;
    border       : 1px solid transparent;

    cursor: pointer;
}

.Selected {
    background: var(--07-vucos-grey);
}

.Focused,
.Item:hover {
    background  : var(--main-color);
    border-color: var(--main-color);
    color: var(--main-text);
}