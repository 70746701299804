.Container {
    background-color: var(--09-vucos-grey);

    padding-left: 3vw;

}

.Content {
    display : flex;
    position: relative;
}

.EmptyText {
    margin-top: 5vh;

    font-weight: 400;
    font-size  : var(--font-21);
    line-height: var(--line-120);

    color: var(--04-vucos-grey);
}