.ImgItem {
    opacity: 0.5;

    color: var(--main-text);
}

.ImgItem:not(.Active) {
    cursor: pointer;
}

.InlineBlock {
    display: inline-block;
}

.Item {
    text-align: center;

    cursor: pointer;
}

.Item * {
    position  : relative;
    z-index   : 1;
    text-align: center;
}

.ImgItem.Active,
.ImgItem:hover {
    opacity: 1;

    /* color: var(--main-color); */
    color: var(--02-vucos-grey);
}

.ImgItem.Active>div {
    color: var(--main-color);
}

.ImgContainer {
    position: relative;

    width  : 130px;
    height : 130px;
    display: inline-block;
}

.Img {
    height    : 100%;
    width     : 100%;
    object-fit: cover;

    border-radius: 50%;
}

.Shape,
.Duck,
.Pin {
    position: absolute;
}

.Shape {
    width : 100%;
    height: 100%;

    top : 0;
    left: 0;
}

.Left {
    left: 0;
}

.Item path,
.Item circle {
    color: var(--main-color);
}

.Duck,
.Pin {
    width : 32%;
    height: 32%;

    bottom: 0;
    right : 0;
}

.Text {
    margin-top: 15px;

    font-weight: normal;
    font-size  : 1.2rem;
    line-height: 120%;

    text-align: center;

    margin-bottom: 40px;
}

.ImgItem:hover .Text {
    color: var(--main-color);
}

.Show {
    visibility: visible;
}

.Hide {
    visibility: hidden;
}

.EditButton {
    visibility: hidden;
}

.Item:hover .EditButton,
.ImgItem.Active+.EditButton {
    visibility: visible;
}

.HourList {
    font-weight: bold;

    margin-top: 10px;

    list-style-position: inside;
    list-style-type    : circle;
}