.Container {
    position: fixed;
    top     : 0;
    left    : 0;

    z-index: 2;

    height: 100vh;
    width : 100vw;

    display: flex;
}

.SubMenu {
    position: relative;

    max-width: calc(350px + 80px);
    min-width: calc(350px + 80px);

    height: 100%;
}

.Content {
    width : 100%;
    height: 100vh;
}