.Container,
.Main {
    background: var(--09-vucos-grey);
    color: var(--main-text);

    height: 100vh;

    overflow: hidden;

    display       : flex;
    flex-direction: column;

    --color-transparent: var(--09-vucos-grey);
}

.Main {}

.Invisible {
    opacity: 0;
}