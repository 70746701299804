.Header {
	font-weight  : 700;
	font-size    : var(--font-25);
	line-height  : 120%;
	margin-bottom: 34px;
}

.Text {
	font-weight: 400;
	font-size  : var(--font-20);
	line-height: 145%;

	width     : 100%;
	max-height: 30vh;
	overflow-y: auto;

}

.Button {
	border-radius: 8px;
	font-family  : 'Montserrat';
	font-style   : normal;
	font-weight  : 400;
	font-size    : 1rem;
	line-height  : 120%;
	padding      : 15px 0;
	width        : 288px;
	height       : unset;
	margin-top   : 30px;
}

.Focused {
	color     : var(--main-text);
	background: var(--main-color);
}

.SimpleMsgContent {
	display       : flex;
	flex-direction: column;
	align-items   : center;
}


/* PERSISTENT MSG */
.MsgContent {
	width : 100vw;
	height: 100vh;

	background-color: #16161D;

	position: fixed;
	top     : 0;
	left    : 0;

	padding: 40px;

	display    : flex;
	align-items: center;
}

.ImgContainer {
	width : 60%;
	height: 100%;


	background: linear-gradient(90deg, #16161D 1.92%, rgba(22, 22, 29, 0) 52.08%, #16161D 96.52%),
		linear-gradient(180deg, #16161D 0%, rgba(0, 0, 0, 0) 28.36%, #16161D 100%);
}

.ImgContainer>img {
	width     : 100%;
	height    : 100%;
	object-fit: cover;

	position: relative;
	z-index : -1;
}

.TextContainer {
	width : 40%;
	height: fit-content;

	display       : flex;
	flex-direction: column;
	align-items   : center;

	text-align: center;

	padding: 0 15px;

	font-size: var(--font-18);
}

.Line {
	width     : 100%;
	align-self: flex-start;
}

.MsgContent .Header {
	font-size  : var(--font-25);
	font-weight: 700;
	line-height: var(--line-120);
	text-align : center;

	color: var(--main-text);

	margin-bottom: 44px;
}

.MsgContent .Text {
	font-size  : var(--font-18);
	font-weight: 400;
	line-height: var(--line-145);
	text-align : center;

	color: #969696;

	margin-bottom: 10vh;

	max-height: 25vh;
	overflow-y: auto;
}

.ReminderList {
    max-width : 40vw;
    overflow-y: auto;

    margin-top: 20px;

    display  : flex;
    flex-wrap: nowrap;
}

.ReminderList>*:last-child {
    margin-right: 50%;
}