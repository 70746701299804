.Container {
    position: fixed;
    top     : 0;
    left    : 0;

    width : 100vw;
    height: 100vh;

    z-index: 1;
}

.Blackout {
    width : 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 1);

    display        : flex;
    justify-content: center;
    align-items    : center;
}

.Msg {
    color: var(--main-text);

    width: 50%;

    font-weight: 700;
    font-size: var(--font-25);
    line-height: 120%;

    text-align: center;
}

.Img {
    position: fixed;
    z-index : 5;

    top  : 50px;
    right: 50px;

    width     : 150px;
    object-fit: cover;
}