.SectionPanel {
    width     : 100vw;
    align-self: flex-start;

    display    : flex;
    align-items: center;
    flex-wrap  : wrap;

    padding: 30px 8vw;

}

.SectionPanel>*:last-child {
    margin-right: 0;
}


.SectionContainer {}


.SectionContainer>div {
    height: 300px;
}

.SectionContainer.Channel>div {
    height: 350px;

    min-height: fit-content;
}

.SectionContainer.NoHeight>div {
    height: 100vh;
}