.Container {
    position: fixed;
    top     : 0;
    left    : 0;

    width : 100vw;
    height: 100vh;

    background: rgba(0, 0, 0, 0.8);

    z-index: 20;

    display        : flex;
    justify-content: center;
    align-items    : center;
}

.Content {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
}

.DataContainer {
    display    : flex;
    align-items: flex-end;

    margin-bottom: 30px;
}

.TimeSection {
    display       : flex;
    flex-direction: column;
    align-items   : center;
}

.TimeSection>label {
    font-weight: 400;
    font-size: var(--font-19);
    line-height: var(--line-120);

    color: var(--04-vucos-grey);

    height       : 66px;
    margin-bottom: 14px;
}

.NumberContainer {
    display: flex;
}

.NumberContainer>* {
    margin: 0 15px;
}

.ActionContainer>* {
    margin: 0 15px;
}

.Separator {
    /* height: 120px; */

    display        : flex;
    justify-content: center;
    align-items    : flex-end;

    margin: 0 5px;
}

.Input input {
    font-weight: 300;
    font-size: var(--font-30);
    line-height: var(--line-120);

    color: var(--main-text);

    text-align: center;

    width : calc(100px * 0.5);
    height: calc(120px * 0.5);

    padding: 0;
    margin : 0;
}