.Container {
    height: 100vh;
    width : 100%;

    overflow: hidden;

    background: var(--09-vucos-grey);

    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
}


.Text {
    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 700;
    font-size: var(--font-96);
    line-height: 120%;

    color: var(--main-text);

    margin-top: 60px;
}

.ImgLogo {
    width: 250px;
}