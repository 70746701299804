.Item {
    flex       : 1 1 auto;
    display    : flex;
    align-items: center;

    height   : 77px;
    min-width: fit-content;

    font-family: 'Montserrat';
    font-style : normal;

    background   : hsla(240, 7%, 18%, 0.50);
    /* opacity   : 0.5; */

    margin-bottom: 7px;

    border: 3px solid transparent;

    cursor: pointer;
}

.Item.Focused,
.Item:hover {
    border-color: var(--main-color);
}

.Item.Selected {
    background: var(--07-vucos-grey);
}

.Number {
    display        : flex;
    align-items    : center;
    justify-content: center;

    min-width   : fit-content;
    /* width    : 54px; */
    height      : 100%;
    padding     : 5px 15px;

    background: hsla(240, 14%, 10%, 0.50);

    font-weight: 400;
    font-size  : var(--font-17);
    line-height: var(--line-120);

    text-align: center;

    color: var(--04-1-vucos-grey);
}

.ContentContainer {
    display        : flex;
    align-items    : center;
    justify-content: space-between;

    width: 100%;
}

.ItemContent {
    display    : flex;
    align-items: center;
}

.ImgContainer {
    max-width: 80px;
    min-width: 80px;

    padding: 0 5px;

    text-align: center;
}

.Logo {
    max-width : calc(100% - 10px);
    max-height: 60px;
    object-fit: cover;
}

.Name {
    font-weight: 500;
    font-size  : var(--font-17);
    line-height: 125%;

    color: var(--02-vucos-grey);

    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.Item svg {
    color: var(--main-color);

    margin-right: 20px;
}