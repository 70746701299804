.Section {
    background-color: var(--09-vucos-grey);

    /* padding: 233px 105px 90px 107px; */
    padding: 10vh 5vw 5vh;

    max-width: 90vw;
}

.Container {
    /* background-color: var(--09-vucos-grey); */

    /* padding: 145px 105px 90px 107px; */
    /* height: 100%; */

    overflow: hidden;
}

.Header {
    font-style : normal;
    font-weight: 400;
    font-size  : var(--font-25);
    line-height: 120%;

    color: var(--02-vucos-grey);

    /* margin-bottom: 52px; */

    position: absolute;
    top     : 3vh;
}

.Content {
    display  : flex;
    flex-wrap: wrap;

    max-width: calc(var(--movie-w) * 0.43 * 4 + 2px * 2 * 4 + 3px);
    min-width: calc(var(--movie-w) * 0.43 * 4 + 2px * 2 * 4);

    max-height: calc(100vh - 10vh);
    min-height: fit-content;

    overflow-y: auto;

    margin-bottom: 90px;
}

.Content>* {
    margin: 3.5px 2px;
}

.NextAll {
    height: 100%;
    width : 100%;

    display        : flex;
    flex-direction : column;
    justify-content: space-around;
    align-items    : center;
}

.NextAll p {
    font-style : normal;
    font-weight: 400;
    font-size  : var(--font-23);
    line-height: 120%;

    color: var(--07-vucos-grey);

    text-align: center;
}

.NoItem {
    background   : linear-gradient(180deg, var(--09-vucos-grey) 0%, #000000 100%);
    border-radius: 4px;
}

.Loading {
    max-width: 600px;
}