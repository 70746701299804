.Container {
    position: fixed;
    top     : 0;
    left    : 0;

    z-index: 9000;

    width : 100vw;
    height: 100vh;

    display        : flex;
    justify-content: center;
    align-items    : center;

    color: var(--main-text);
}

.Background {
    position: absolute;
    top     : 0;
    left    : 0;

    width : 100%;
    height: 100%;

    background-color: #000000CC;
}

.Modal {
    position: relative;
    z-index : 2;

    background: var(--09-vucos-grey);
    box-shadow: 0px 4px 100px 0px #000000;

    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;

    border-radius: 12px;

    padding: 50px 40px 36px;

    width    : 50%;
    max-width: 600px;

    text-align: center;

    animation-name    : fromabove;
    animation-duration: 0.5s;
}

@keyframes fromabove {
    from {
        top: -100px;
    }

    to {
        top: 0px;
    }
}

.Header {
    font-size  : var(--font-25);
    font-weight: 700;
    line-height: var(--line-120);

    margin-bottom: 24px;
}

.Text {
    font-size  : var(--font-20);
    font-weight: 400;
    line-height: var(--line-145);

    margin-bottom: 48px;
}

.Button {
    width : 100%;
    height: 50px;
}