.Container {
    display       : flex;
    flex-direction: column;

    /* padding: 72px 0 80px 97px; */
    padding: 30px 0 30px 65px;

    background: linear-gradient(90deg, var(--09-vucos-grey) 0%, var(--11-vucos-grey) 100%);
}

.Content {
    /* margin-right: 25px; */
}

.Header {
    font-style : normal;
    font-weight: 400;
    font-size  : var(--font-20);
    line-height: 120%;

    /* 06-vucos-grey */
    /* color: var(--06-vucos-grey); */
    color: var(--main-text);

    margin-bottom: 18px;
}

.List {
    list-style: none;

    /* max-height: 200px; */
    overflow-x: auto;

    display: flex;
}


.Item {
    font-size: var(--font-18);

    padding: 12px 23px;

    width      : fit-content;
    white-space: nowrap;

    margin-right: 20px;

    color: var(--main-text);
}

/* .Item>p {
    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
} */