.Item {
    min-width: 150px;
    height   : 120px;
}

.ImgContainer {
    text-align: center;

    background: hsla(240, 7%, 18%, 1);

    height: calc(100% - 25px);

    display        : flex;
    justify-content: center;
    align-items    : center;
}

.ImgContainer img {
    max-width: 100px;
    height   : 100%;
}

.TextContainer {
    min-height: 25px;
    padding   : 0px 10px;


    background: var(--09-vucos-grey);

    display        : flex;
    justify-content: space-between;
    align-items    : center;

}

.TextContainer p {
    font-weight: 400;
    font-size  : var(--font-17);
    line-height: var(--line-100);

    color: var(--06-vucos-grey);
}

.TextContainer svg {
    color: var(--main-color);

    width: 15px;
}