.SortContainer {
    height: max-content;
    width    : 50vw;

    display       : flex;
    flex-direction: column;
}

.SortContent {
    margin: 20px 0px;

    display  : flex;
    flex-wrap: wrap;

    overflow: auto;
}

.SortTitle {
    font-weight: 400;
    font-size  : var(--font-19);
    line-height: var(--line-120);

    color: var(--main-text);

    margin-top: 30px;
}

.SortItem {
    font-weight: 400;
    font-size  : var(--font-18);

    color           : var(--04-1-vucos-grey);
    background-color: transparent;

    margin-right : 10px;
    margin-bottom: 10px;
    padding      : 0 13px;

    height: 40px;
    width : fit-content;

    white-space: nowrap;

    border       : none;
    border-radius: 8px;

    display    : flex;
    align-items: center;
}

.SortItem.Active {
    background-color: var(--07-vucos-grey);
}

.Focused.SortItem,
.SortItem:hover {
    background-color: var(--main-color);
    color           : var(--main-text);
}