.Item>div {
    font-weight: 500;
    font-size  : var(--font-17);
    line-height: var(--line-120);

    color: var(--06-vucos-grey);

    margin-bottom: 10px;
    padding      : 10px;

    border: 3px solid transparent;

    display: flex;

    cursor: pointer;
}

.LiveItem {
    height: 30vh;
}

.LiveItemImg {
    width: 100%;

    position: relative;
}

.NotLiveItem {
    height: 16vh;
}

.Focused>div,
.Item:hover>div {
    border-color: var(--main-color);
    color       : var(--main-color);
}

.TimeContainer {
    display       : flex;
    flex-direction: column;
    align-items   : center;

    /* min-width: 80px;
    max-width: 80px; */

    padding: 0 10px;
}

.TimeContainer svg {
    width     : 45%;
    height    : auto;
    max-height: 30%;

    margin-top: 20px;
}

.Time {
    font-size : var(--font-19);
    text-align: center;
    color     : var(--main-text);
}

.ImgContainer {
    max-width : 40%;
    min-width : 40%;

    height: 100%;

    margin: 0 15px 0 4px;
}

.Img {
    width        : 100%;
    height       : 100%;
    border-radius: 4px;

    object-fit     : cover;
    object-position: center;
}

.Content {}

.Type {
    color: var(--06-vucos-grey);
}

.Name {
    color: var(--main-text);

    margin-bottom: 15px;

    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.Text {
    color: var(--03-vucos-grey);

    display           : -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.ActionContainer {
    font-weight: 400;
    font-size  : var(--font-20);
    line-height: var(--line-120);

    color: var(--main-text);

    height: 94px;
    width : 100%;

    padding: 3%;

    position: absolute;
    bottom  : 0;
    left    : 0;

    display        : flex;
    justify-content: space-between;
    align-items    : center;

    background: hwb(240 9% 89% / 0.6);
}

.RedDot {
    display: inline-block;

    width : 10px;
    height: 10px;

    border-radius: 50%;

    background-color: var(--red);

    margin-right: 10px;

    animation: appear 1s infinite;
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 0.6;
    }

    100% {
        opacity: 1;
    }
}

.Button {
    height: 45px;

    padding-right: 20px;
    padding-left : 20px;

    min-width: 30%;
}

.Focused .Button {
    background-color: var(--main-color);

    color: var(--main-text);


}