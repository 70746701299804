.Container {
    height: 100vh;
    width : 100%;

    overflow: hidden;

    background-repeat    : no-repeat;
    background-size      : 100%;
    background-position-y: top;
    background-position-x: right;

    background-size: cover;
}

.FullContainer {
    height: 100vh;
    width : 100%;

    display        : flex;
    justify-content: center;
    align-items    : center;
}

.Content {
    margin-top : 5vh;
    margin-left: 5vw;

    width    : 65%;
    min-width: 600px;
}

.ImgContainer {
    height: 100%;
}

.BgImage {
    position: absolute;
    top     : 0;
    right   : 0;
    z-index : -1;

    width     : 100%;
    object-fit: cover;
}

.Filter {
    background: linear-gradient(89.98deg, var(--09-vucos-grey) 0.02%, rgba(22, 22, 29, 0.8) 46.75%, rgba(0, 0, 0, 0) 66.19%),
        linear-gradient(0deg, var(--09-vucos-grey) 0%, rgba(0, 0, 0, 0) 43.83%);

    width : 100%;
    height: 100%;

    position: absolute;
    top     : 0;
    right   : 0;
    z-index : -1;
}

.Placeholder {
    width : 100%;
    height: 100%;

    background-color: var(--09-vucos-grey);

    position: absolute;
    top     : 0;
    right   : 0;
    z-index : -2;
}

.ButtonSpace {
    height: 30px;
}

@media(max-height: 1500px) {
    .Content {
        width    : 60%;
        min-width: unset;
    }
}

@media(max-height: 780px) {
    .Content {
        width    : 80%;
        min-width: unset;
    }
}