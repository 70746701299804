.Container {
    margin-bottom: 20px;
}

.Header {
    font-weight: 400;
    font-size  : var(--font-20);
    line-height: var(--line-120);

    color: var(--main-text);
}

.Text {
    font-weight: 500;
    font-size  : var(--font-18);
    line-height: var(--line-120);

    color: var(--02-vucos-grey);

    margin: 15px 0;
}

.DetailContainer {
    display    : flex;
    align-items: center;
}

.TotalHour {
    margin-right: 21px;

    width : 20%;
    height: 15px;

    border: 1px solid var(--06-vucos-grey);
}

.UsedHour {
    height: 100%;

    background: var(--main-color);
}

.DetailText {
    font-weight: 400;
    font-size  : var(--font-17);
    line-height: var(--line-120);

    color: var(--04-vucos-grey);
}