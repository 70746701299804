.Item {
    width: calc(25% - 20px);
}

.Img {
    width : 100%;
    height: 150px;

    object-fit: cover;
}

.Name {
    margin-top   : 10px;
    margin-bottom: 10px;

    font-weight: 500;
    font-size  : var(--font-20);
    line-height: var(--line-120);

    color: var(--03-vucos-grey);
}

.Title {
    font-weight: 700;
    font-size  : var(--font-21);
    line-height: var(--line-100);

    min-height: calc(var(--font-21) * 2);

    color: var(--main-text);

    display           : -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.DetailContainer {
    display       : flex;
    flex-direction: column;
}

.Progress {
    display        : flex;
    justify-content: space-between;

    margin-bottom: 10px;
}

.Total {
    width : 100%;
    height: 4px;
    margin: 10px 0;

    background: var(--07-vucos-grey);
}

.Completed {
    height: 100%;

    background: var(--04-vucos-grey);
}

.Icon {
    display    : flex;
    align-items: center;

    margin-bottom: 10px;
}

.Icon>svg {
    margin-right: 10px;
}

.Text {
    font-weight: 500;
    font-size  : var(--font-20);
    line-height: var(--line-120);

    color: var(--05-vucos-grey);
}

.Highlight {
    color: var(--main-color);
}