.Container {
    display       : flex;
    flex-direction: column;

    /* padding: 72px 0 80px 97px; */
    padding: 30px 65px;

    background: linear-gradient(90deg, var(--09-vucos-grey) 0%, var(--11-vucos-grey) 100%);
}

.Container>* {
    margin-bottom: 25px;
}

.Container>*:last-child {
    margin-bottom: 0;
}

.Header {
    font-style : normal;
    font-weight: 400;
    font-size  : var(--font-20);
    line-height: 120%;

    color: var(--main-text);

    margin-bottom: 18px;
}

.List {
    list-style: none;

    /* max-height: 200px; */
    overflow  : auto;

    display: flex;

    width: 100%;
}

.Item {
    font-size: var(--font-18);

    padding: 12px 23px;

    width: fit-content;

    margin-right: 20px;

    color: var(--main-text);
}