.Container {
    padding-bottom: 14px;
}

.Container::after {
    content: "";

    display: block;

    height          : 5px;
    width           : 0;
    background-color: var(--main-color);
    border-radius   : 100px;

    transition: width 0.4s ease-in;
}

.Container.Active::after {
    width: 100%;
}

.Item {
    font-size  : var(--font-25);
    line-height: 120%;
    font-weight: 400;

    text-align: center;
    color     : var(--04-vucos-grey);

    background: none;

    height: 100%;

    text-align: center;
}

.Active .Item {
    color: var(--main-color);
}