.Item {
    color: var(--main-color);

    cursor: pointer;
}

.Img {
    width        : 100px;
    height       : 100px;
    border-radius: 50%;

    object-fit: cover;
}

.Shape {
    display: none;
}

.FocusedImg .Shape,
.Item:hover .Shape {
    display: inline-block;

    top     : 0;
    left    : 0;
    position: absolute;
    width   : 100%;
    height  : 100%;

}