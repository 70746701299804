.Container {
    overflow: hidden;

    position: fixed;
    top     : 0;
    left    : 0;
    z-index : 2;

    height: 100vh;
    width : 100vw;

    display: flex;

    background: var(--09-vucos-grey);
}

.Container>* {
    background: var(--09-vucos-grey);
}

.EmptyContainer {
    position: relative;

    width: 100%;

}

.Content {
    overflow: hidden;

    height: 100vh;

    padding-top: 5vh;

    flex: 1 1 auto;

    display: flex;
}


.ChannelContainer,
.EpgContainer {
    height: calc(100vh - 5vh);

    overflow-y: auto;
    overflow-x: hidden;
}

.ChannelContainer {
    padding: 0 5px 0 3vw;

    min-width: 40%;
    max-width: 40%;
}

.EpgContainer {
    padding: 0 5px 0 1vw;

    min-width: 60%;
    max-width: 60%;
}

.EpgContainer>div:last-of-type {
    margin-bottom: 50vh;
}


.DragItem,
.DragWrapper {
    padding: 0 !important;
    border : 0 !important;
}

.SubMenu {
    position: relative;

    width: fit-content;

    height: 100vh;

    max-width: 350px;
    min-width: 350px;

    padding: 5vh 3vw;

    background: linear-gradient(90deg, var(--09-vucos-grey) 0%, var(--11-vucos-grey) 100%);

}