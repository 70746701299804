.Container {
    display       : flex;
    flex-direction: column;
    align-items   : flex-start;

    width: 100%;
}

.CountDown {
    width: 100%;

    display        : flex;
    justify-content: space-between;
    align-items    : center;

    font-size  : var(--font-18);
    font-weight: 400;
    line-height: var(--line-145);
    text-align : left;

    color: #969696;

    margin-bottom: 25px;
}

.Line {
    width        : 100%;
    height       : 6.15px;
    border-radius: 100px;

    background-color: #2B2B31;

    display        : flex;
    justify-content: flex-start;
    align-items    : center;
}

.Completed {
    display: inline-block;

    width        : 0;
    height       : 100%;
    border-radius: 100px;

    background-color: #00A6FF;
}