.Item {
    padding      : 32px 30px;
    margin-bottom: 22px;

    border-radius: 8px;

    background-color: var(--08-vucos-grey);

    display        : flex;
    justify-content: space-between;
}

.Item>*:first-child {
    width: 55%;
}

.Item>*:last-child {
    width: 45%;
}

.Item .TextContainer {
    display        : flex;
    flex-direction : column;
    justify-content: space-between;


    margin-right: 15px;
}

.Inactive.Item .TextContainer>p {
    font-size  : var(--font-24);
    font-weight: 700;
    line-height: 130%;
}

.PackageList {
    font-size  : var(--font-20);
    font-weight: 700;
    line-height: 90%;

    list-style-type    : disc;
    list-style-position: inside;

    color: var(--04-1-vucos-grey);
}

.Active.Item .PackageList {
    color: var(--main-text);
}

.PackageList>li {
    margin-bottom: 18px;
}

.PackageList>li:last-of-type {
    margin-bottom: 0;
}

.Item .Info {
    display    : flex;
    align-items: center;
}

.Item .Info>p {
    margin-right: 30px;
    margin-left : 6px;
}


/* .FormContainer {
    width: 45%;
} */

.Input input {
    background-color: var(--07-vucos-grey);
    color           : var(--04-1-vucos-grey);

    height: 70px;

    padding      : 13px 24px;
    margin-bottom: 20px;

    border-radius: 4px;
}

.ActiveForm .Input input {
    color: var(--main-text);
}

.Input input::placeholder {
    color: var(--04-1-vucos-grey);
}

.Input.Filled input {
    padding: 33px 24px 13px;
}

.Button {
    width : 100%;
    height: 60px;
}

.ActiveForm .Button {
    background-color: var(--main-color);
    color: var(--main-text);
}

@media(max-width: 1000px) {
    .Item {
        background-color: var(--08-vucos-grey);

        display       : flex;
        flex-direction: column;
    }

    .Item>* {
        width: 100% !important;
    }

    .TextContainer {
        margin-right : 0;
        margin-bottom: 15px;
    }

    .TextContainer>* {
        margin-bottom: 15px;
    }

}