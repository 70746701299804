body {
    --font-14 : 0.875rem;
    --font-16 : 0.9rem;
    --font-17 : 1rem;
    --font-18 : 1.125rem;
    --font-19 : 1.2rem;
    --font-20 : 1.25rem;
    --font-21 : 1.313rem;
    --font-22 : 1.375rem;
    --font-23 : 1.438rem;
    --font-24 : 1.5rem;
    --font-25 : 1.563rem;
    --font-26 : 1.625rem;
    --font-27 : 1.688rem;
    --font-28 : 1.75rem;
    --font-30 : 1.875rem;
    --font-32 : 2rem;
    --font-33 : 2.063rem;
    --font-34 : 2rem;
    --font-35 : 2.1rem;
    --font-40 : 2.5rem;
    --font-45 : 2.8rem;
    --font-50 : 3.125rem;
    --font-60 : 3.75rem;
    --font-96 : 6rem;
    --font-128: 8rem;

    --line-75 : 75%;
    --line-80 : 80%;
    --line-100: 100%;
    --line-120: 120%;
    --line-125: 125%;
    --line-130: 130%;
    --line-136: 136%;
    --line-145: 145%;
    --line-150: 150%;
    --line-160: 160%;
    --line-200: 200%;
    --line-240: 240%;
}

/* responsive changes... */
/* @media(max-width: 1100px) {
    body {
    }
} */