.Item {
    flex-shrink    : 0;
    margin-right   : 2px;
    position       : relative;

    cursor: pointer;

    --view-width : calc(var(--movie-w) * 0.55);
    --view-height: calc(var(--movie-h) * 0.55);

    padding-right: calc(100% - var(--view-width) - 5px) !important;
}

.FocusPoint {
    height  : 40px;
    position: absolute;
    top     : -53px;
    width   : 100%;
}

.Wrapper {
    width        : var(--view-width);
    height       : var(--view-height);
    position     : relative;
    border-radius: 4px;
    border       : 5px solid #2E2E36;
}

.Focused .Wrapper {
    /* .Wrapper:hover  */
    border-color: var(--main-color);
}

.Detail {
    width          : 100%;
    height         : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: space-around;
    align-items    : center;
    background     : linear-gradient(180deg, var(--09-vucos-grey) 0%, #000000 100%);
}

.Detail svg {
    width: 50px;
}

.Title {
    width             : 90%;
    font-weight       : 400;
    font-size         : var(--font-20);
    line-height       : 120%;
    color             : var(--04-1-vucos-grey);
    text-align        : center;
    display           : -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow          : hidden;
}