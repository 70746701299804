.HeaderContainer {
	display      : flex;
	align-items  : center;
	margin-bottom: 51px;

	width    : 100%;
}

.HeaderContainer svg {
	margin-right: 5px;

	height: var(--font-22);
	width: var(--font-22);
	min-height: var(--font-22);
	min-width: var(--font-22);
}

.Button {
	display    : flex;
	align-items: center;

	padding: 10px;
}

.Text {
	white-space: break-spaces;

	display: inline-block;
	max-width: 200px;
	/* min-width: 150px; */

	display           : -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.Header {
	font-weight: 700;
	font-size  : var(--font-20);
	line-height: 120%;
	color      : var(--main-text);
	border     : none;
	padding    : 0;
	min-width  : unset;
	height     : unset;
	width      : fit-content;
	width      : auto;
	text-align : left;

	display: inline-block;
    width: 100%;
}

.Header.Focused,
.Header:hover {
	color           : var(--main-color);
	background-color: transparent;
}