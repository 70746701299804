.Container {
    color     : var(--main-text);
    background: #16161D;

    height: 100vh;
    width : 100vw;

    position: fixed;
    top     : 0;
    left    : 0;
    z-index : 10;

    display       : flex;
    flex-direction: column;
    align-items   : center;

    padding-top: 10vh;
}

.Header {
    font-size  : var(--font-24);
    font-weight: 400;
    line-height: var(--line-200);

    margin-bottom: 25px;
}

.Text {
    font-size  : var(--font-20);
    font-weight: 400;
    line-height: var(--line-200);
    text-align : center;

    color: var(--04-vucos-grey);

    margin-bottom: 47px;
}

.NumberContainer {
    display        : flex;
    justify-content: center;

    margin-bottom: 50px;
}

.NumberContainer>* {
    margin: 0 15px;
}

.ActionContainer>* {
    margin: 0 15px;

    height   : 50px;
    min-width: 200px;
}


.Input input {
    font-weight: 300;
    font-size  : var(--font-30);
    line-height: var(--line-120);

    color: var(--main-text);

    text-align: center;

    width : calc(72px * 0.85);
    height: calc(80px * 0.85);

    padding: 0;
    margin : 0;
}

.ProfileItem {
    display       : flex;
    flex-direction: column;
    justify-content: center;

    margin-bottom: 25px;
}

.ProfileItem img {
    width : 100px;
    height: 100px;

    border-radius: 50%;
}

.ProfileItem p {
    font-size  : var(--font-23);
    font-weight: 400;
    line-height: var(--line-100);
    text-align : center;

    margin-top: 10px;

    color: var(--main-color);

}