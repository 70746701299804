.Container {
	height         : 100vh;
	background     : linear-gradient(90deg, var(--09-vucos-grey) 0%, var(--11-vucos-grey) 100%);
	display        : flex;
	justify-content: center;
	align-items    : center;

	position: relative;
}

.Back {
	position: absolute;
	top     : 70px;
	left    : 63px;
}

.SectionNav {
	/* padding-top       : 179px; */
	/* padding-left      : 105px; */
	/* padding-right     : 103px; */
	/* padding-bottom    : 123px; */
	max-width         : 1001px;
	width             : 66%;
	display           : flex;
	flex-direction    : column;
	/* justify-content: space-between; */
	justify-content   : flex-end;
	flex              : 1 1 auto;
}

.SectionNav>nav {
	display           : flex;
	/* justify-content: center; */
	align-items       : flex-start;
}

.QRImage {
	justify-self: center;
	align-self  : center;
	width       : 58.5%;
}

.QRImage>img {
	width: 100%;
}

.Buttons {
	display        : flex;
	justify-content: space-around;

	margin-top     : 1.8rem;
}

.Button {
	width: 55%;
}

.SectionContent {
	/* background-image: url("../../assets/img/img_login_bg.png");
    background-position: right; */
	/* padding  : 160px 132px 10px 127px; */
	color    : var(--02-vucos-grey);
	flex     : 1 1 auto;
	max-width: 540px;
}