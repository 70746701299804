.Container {
    width : 951px;
    height: 100%;

    padding-left  : 162px;
    padding-bottom: 128px;

    display        : flex;
    flex-direction : column;
    justify-content: end;

    /* 02-vucos-grey */
    color: var(--02-vucos-grey);
}

.TextContainer {
    width: 83%;

    font-weight: 400;
    font-size: var(--font-25);
    line-height: 150%;

    /* 02-vucos-grey */
    color: var(--02-vucos-grey);

    margin-bottom: 187px;
}

.TextContainer>img {
    width: 53px;

    margin-bottom: 23px;
}

.Heading {
    font-weight: 300;
    font-size: var(--font-30);
    line-height: 150%;

    /* 05-vucos-yellow */
    color: var(--main-color);

    margin-bottom: 50px;
}

.Buttons {
    display        : flex;
    justify-content: space-between;
}

.Button {
    padding: 0 30px;
}