.Container {
    height: 100%;

    background: linear-gradient(90deg, var(--09-vucos-grey) 0%, var(--11-vucos-grey) 100%);

    display: flex;
}

.ContainerFull {
    height: 100%;
}

.SectionNav {
    padding-top   : 179px;
    padding-left  : 105px;
    padding-right : 103px;
    padding-bottom: 123px;

    max-width: 800px;
    width    : 66%;

    display        : flex;
    flex-direction : column;
    justify-content: space-between;

    flex: 1 1 auto;
}

.SectionNav>nav {
    display    : flex;
    align-items: flex-start;
}

.QRImage {
    justify-self: center;
    align-self  : center;

    width: 58.5%;
}

.QRImage>img {
    width: 100%;
}

.Buttons {
    align-self: self-end;

    display        : flex;
    justify-content: space-between;
}

.SectionContent {
    display        : flex;
    flex-direction : column;
    justify-content: space-between;

    background-image   : url("../../assets/img/img_signup_bg.png");
    background-position: right;

    padding: 160px 132px 65px 127px;

    color: var(--02-vucos-grey);

    flex     : 1 1 auto;
    max-width: 919px;
}

.FullSection {
    background-image   : url("../../assets/img/img_signup_bg.png");
    background-position: right;

    height: 100%;
}

.Notice {
    font-weight: 400;
    font-size  : var(--font-25);
    line-height: 150%;
}

.Notice a {
    color: var(--main-color);

    display: inline-block;
    width  : fit-content;
}

.Notice .Focused {
    text-decoration: underline;
}