.Item {
    width : 400px;
    height: 70px;

    padding: 5px 20px;

    background   : #2B2B3180;
    border-radius: 8px;

    display        : flex;
    align-items    : center;
    justify-content: flex-start;

    margin-bottom: 15px;
    margin-right : 20px;

    border: 1px solid transparent;

    cursor: pointer;
}

.Content {
    width: 75%;

    display    : flex;
    align-items: center;

    justify-content: space-between;
}

.ImgContainer {
    height: 100%;

    width: 25%;

    display        : flex;
    justify-content: center;
    align-items    : center;
}

.Item.Focused {
    border-color: var(--main-color);
}

.Text {
    font-weight: 400;
    font-size  : var(--font-19);
    line-height: var(--line-145);

    color: var(--04-1-vucos-grey);


    display           : -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.Focused .Text {
    color: var(--main-text);
}

.Img {
    display: inline-block;

    height    : 100%;
    width     : auto;
    object-fit: contain;
}