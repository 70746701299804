.Wrapper {
    border       : 3px solid transparent;
    border-radius: 4px;

}

.Wrapper:not(.Wrapper.Light) {
    cursor: pointer;
}

.Wrapper.Light {
    opacity: 0.6;

    cursor: move;
}

.Item {
    display    : flex;
    align-items: center;

    padding      : 8px;
    padding-right: 30px;

    height: 100%;
}

/* .Wrapper.Light.Focused {
    opacity: 1;
} */

.Wrapper:not(.Light):hover {
    border-color: var(--main-color);
}

/* .Wrapper:not(.Light):hover,
.Focused {
    border-color: var(--main-color);
} */

.DragIcon {
    margin-right: 10px;
    margin-left : 10px;

    color: var(--04-1-vucos-grey);
}