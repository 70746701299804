.Container {
    background-color: var(--09-vucos-grey);
    height          : 100%;

    width: 100%;

    overflow: hidden;

    /* position: relative; */

    display: flex;

    padding-top: 87px;
}

.SearchSection {
    width: 35%;
}

.SearchSection>* {
    width: 85%;
}

.ResultSection {
    width: 65%;
    padding-right: 20px;

}
