.Container {
    background-color: var(--09-vucos-grey);

    padding: 105px 155px 130px 120px;
    height : 100%;

    display        : flex;
    flex-direction : column;
    justify-content: space-between;
}

.Header {
    font-style : normal;
    font-weight: 400;
    font-size: var(--font-25);
    line-height: 120%;

    color: var(--06-vucos-grey);

    margin-bottom: 18px;

}

.Container>article:first-of-type {
    max-height      : 60%;
    overflow        : hidden;

    margin-bottom: 32px;
}

.Container>article:last-of-type {
    max-height: 40%;
    overflow  : hidden;
}

.Container>article:first-of-type>ul,
.Container>article:last-of-type>ul {
    height    : calc(100% - 48px);
    overflow  : hidden;
    overflow-y: auto;
}

.Item {
    padding-top   : 11px;
    padding-bottom: 11px;
    margin-bottom : 8px;
}