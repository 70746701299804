.Header {
    font-weight: 400;
    font-size  : 1rem;
    line-height: var(--line-150);

    /* padding: 16.5px 0; */

    color     : var(--04-vucos-grey);
    background: var(--none);

    border-radius: 8px;

    max-width: 310px;
    min-width: 310px;

    height: 50px;

    text-align: center;

    cursor: pointer;

    margin-right: 10px;

    display        : flex;
    align-items    : center;
    justify-content: center;
}

.Header.Small {
    max-width: 250px;
    min-width: 250px;
}

.Active {
    background: var(--07-vucos-grey);
}

.Focused,
.Header:hover {
    background: var(--main-color);
    color     : var(--main-text);

    cursor: pointer;
}