.FocusItem {
    margin-bottom   : 20px;
    /* margin-left  : 80px; */
}

.Item {
    /* height        : calc(230px * 0.7); */
    /* width  : 40vw; */
    /* padding: 10px; */
    height   : 100%;
    max-width: 40vw;

    display: flex;

    font-weight: 400;
    font-size  : var(--font-20);
    line-height: 120%;

    color: var(--02-vucos-grey);
}

.ImgContainer {
    min-width : 40%;
    max-width : 40%;
    max-height: 100%;
    min-height: 100%;

    background-color: var(--03-1-vucos-grey);
    margin-right    : 34px;
    border-radius   : 4px;
}

.ImgContainer>img {
    width : 100%;
    height: 100%;

    object-fit     : cover;
    object-position: center;
    border-radius  : 4px;
}

.Content {
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
}

.Content>div {
    display: flex;
}

.Title {
    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.Text {
    display           : -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
    color             : var(--04-vucos-grey);
}

.TimeContainer>time {
    font-size   : var(--font-18);
    line-height : var(--line-160);
    font-weight : 300;
    margin-right: 15px;
}

.Active .Title {
    color      : var(--main-color);
    font-weight: bold;
}