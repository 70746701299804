.Container {
    background: var(--09-vucos-grey);

    width : 100vw;
    height: 100vh;

    position: fixed;
    top     : 0;
    left    : 0;
    z-index : 100;
    opacity : 0;

    animation-name           : appear;
    animation-duration       : 0.8s;
    animation-delay          : 0.3s;
    animation-fill-mode      : forwards;
    animation-timing-function: linear;

    display       : flex;
    flex-direction: column;

    overflow: hidden;
}

@keyframes appear {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* kablo tv icon */

.Icon {
    padding-left  : 15px;
    padding-top   : 40px;
    padding-bottom: 30px;
}

.Icon>img {
    height: 90px;
}


.MainSection {
    overflow: hidden;

    display       : flex;
    flex-direction: column;

    padding-left: 80px;
}

.HeaderContainer {
    margin-bottom   : 40px;
    /* margin-bottom: -40px; */

    display       : flex;
    flex-direction: column;
}

.HeaderButton svg {
    margin-right: 10px;

    width: var(--font-19);
}

.HeaderButton {
    font-weight: 700;
    font-size: var(--font-19);
    line-height: var(--line-120);

    color: var(--main-text);

    border   : none;
    min-width: unset;
    height   : unset;
    width    : fit-content;

    padding-right: 15px;

    display    : flex;
    align-items: center;
}

.Focused {
    color: var(--main-color);
}

.ListContainer {
    width: 100%;

    overflow: auto;
}

.ListContainer>section>div {
    margin-left: calc(var(--font-19) + 10px);
}