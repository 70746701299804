.Section {
    background-color: var(--09-vucos-grey);
    padding         : 93px 90px 90px 93px;
}

.Container {
    height: 100%;
}

.HeaderContainer {
    color          : var(--main-text);
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    margin-bottom  : 59px;
}

.HeaderContainer>div {
    display    : flex;
    align-items: center;
}

.Header {
    margin-left: 11px;
    font-style : normal;
    font-weight: 400;
    font-size  : var(--font-30);
    line-height: 120%;
    color      : var(--02-vucos-grey);
}

.ContentContainer {
    height        : 100vh;
    padding-bottom: 90px;
    overflow      : hidden;
}

.Content {
    display  : flex;
    flex-wrap: wrap;

    width    : calc((var(--movie-w) * 0.43 + 3 * 2px) * 4);
    min-width: 200px;
    height   : 100%;

    overflow-y   : auto;
    margin-bottom: 90px;
}

.Content>* {
    margin: 3.5px 4.5px;
}

.NextAll {
    height         : 100%;
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: space-around;
    align-items    : center;
}

.NextAll p {
    font-style : normal;
    font-weight: 400;
    font-size  : var(--font-23);
    line-height: 120%;
    color      : var(--07-vucos-grey);
    text-align : center;
}

.NoItem {
    background   : linear-gradient(180deg, var(--09-vucos-grey) 0%, #000000 100%);
    border-radius: 4px;
}

.Loading {
    max-width: 600px;
}