.Container {
    background: var(--09-vucos-grey);
    color: var(--main-text);

    height: 100vh;
    width : 100vw;

    overflow: hidden;

    display    : flex;
    align-items: flex-start;

    position   : fixed;
    top        : 0;
    /* right   : -200px; */
    left       : 0;
    z-index    : 100;

    opacity: 0;

    animation-name           : appear;
    animation-duration       : 0.8s;
    animation-delay          : 0.3s;
    animation-fill-mode      : forwards;
    animation-timing-function: linear;
}

@keyframes appear {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}