.Title {
    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 400;
    font-size  : var(--font-18);
    line-height: 145%;

    color: #666670;

    margin-bottom: 32px;
}


.Header {
    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 400;
    font-size  : var(--font-25);
    line-height: 120%;

    color: var(--main-color);

    margin-bottom: 20px;

    display        : flex;
    align-items    : center;
    justify-content: center;
}

.Header svg {
    color: var(--main-color);
    fill : var(--main-color);

    margin-right: 17px;
}

.Text {
    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 400;
    font-size  : var(--font-18);
    line-height: 145%;

    color: var(--main-text);

    margin-bottom: 20px;
}

.Button {
    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 400;
    font-size  : 1rem;
    line-height: 120%;

    text-align: center;

    color: var(--main-text);

    /* width : 144px;
    height: 30px; */

    background   : transparent;
    border-radius: 8px;

    margin-top: 30px;
}

.Button.Focused {
    background: var(--main-color);
    color     : var(--main-text);
}

.PointContainer {
    display        : flex;
    justify-content: center;
}

.PointContainer>span {
    margin: 0 5px;
}


.Point {
    width : 10px;
    height: 10px;

    border-radius: 999px;

    background: rgba(255, 255, 255, 0.2);
}

.Lighted {
    background: var(--main-color);
}