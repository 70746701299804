.Container {
    display       : flex;
    flex-direction: column;
}

.ButtonContainer {
    height: 20vh;

    display        : flex;
    align-items    : center;
    justify-content: center;
}

.Content {
    width: 80%;

    margin-left : auto;
    margin-right: auto;

    display       : flex;
    flex-direction: column;

    height    : calc(100vh - 20vh);
    overflow-y: auto;

    /* padding-bottom: 50%; */

    color: var(--main-text);
}

.ContainerEmpty {
    max-width: 80%;

    margin-top  : 10vh;
    margin-left : auto;
    margin-right: auto;

    display       : flex;
    flex-direction: column;
    align-items   : center;

    color: var(--main-text);
}

.ContainerEmpty button {
    margin-top: 30px;
}

.Item {
    margin-bottom: 10vh;

    display        : flex;
    justify-content: center;
}

.Item>* {
    margin-right: 26px;
}

.Item>*:last-child {
    margin-right: 0;
}


.Icon {
    width: 84px;

    text-align: center;
}

.Info {
    font-weight: 400;
    font-size  : var(--font-20);
    line-height: var(--line-120);

    color: var(--02-vucos-grey);

    width: 43%;
}

.Button, .TopButton {
    min-height: unset;
    height    : 50px;
    width     : 200px;

    font-weight: 400;
    font-size  : var(--font-20);
    line-height: var(--line-120);
}