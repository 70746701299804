.Container {
    min-width: 159px;
    max-width: 159px;
}

.Container img {
    width : 100%;
    height: 225px;

}

.Container p {
    width     : 90%;
    margin    : 0 auto;
    margin-top: 10px;

    font-weight: 400;
    font-size  : var(--font-20);
    line-height: var(--line-120);
    text-align : center;

    display           : -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow          : hidden;

    color: var(--main-text);
}