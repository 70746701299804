.Section {
    padding-right: 50px;
    padding-left: 50px;
    padding-top : 105px;

    height  : 100vh;
    overflow: hidden;

    display       : flex;
    flex-direction: column;
}

.Storage {
    padding: 20px;

    margin: 20px 0;

    background-color: var(--06-vucos-grey);

    border-radius: 10px;
}

.StorageText {
    margin: 0 10px;

    font-weight: 400;
    font-size: var(--font-19);
    line-height: var(--line-120);

    color: var(--main-text);
}

.StorageContainer {
    display    : flex;
    align-items: center;
}

.StorageContainer svg path {
    fill: var(--02-vucos-grey) !important;
}

.LineContainer {
    padding: 25px 10px;

    padding-left: 30px;

    background-color: var(--08-vucos-grey);

    flex: 1 1 auto;

    display    : flex;
    align-items: center;

    border-radius: 40px;
}

.Line {
    width           : 100%;
    height          : 5px;
    background-color: var(--06-vucos-grey);
}

.FullLine {
    position: relative;

    height: 100%;

    background-color: var(--02-vucos-grey);
}

.Line,
.FullLine {
    border-radius: 30px;
}


.FullText {
    position: absolute;
    bottom  : 10px;
    left    : 98%;

    font-size: var(--font-17);
}

.DurationText {
    margin     : 0 10px;
    white-space: nowrap;

    font-size: var(--font-17);
}

.SubContainer {
    /* height: calc(100vh - 262px); */

    overflow: auto;
}