.Container {
    /* padding: 137px 71px 50px; */
    padding-left: 50px;
    padding-top : 105px;
}

.Header {
    font-size: var(--font-21);
}

.Content {
    margin-top: 30px;
}

.LoadingContainer {
    position: relative;

    width: 100%;
}