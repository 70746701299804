.LinkItem {
    /* width : 257px; */
    max-width: 290px;
    min-width: fit-content;

    height      : 50px;
    padding     : 10px 20px 12px 20px;
    margin-right: 20px;
    margin-bottom: 20px;

    font-weight: 400;
    font-size  : 1rem;
    line-height: 120%;

    text-overflow: ellipsis;
    white-space  : nowrap;
    overflow     : hidden;

    background-color: transparent;
    color           : var(--main-text);


    display           : flex;
    /* justify-content: center; */
    align-items       : center;
    white-space       : nowrap;
    cursor            : pointer;
}

.LinkItem>svg {
    display: inline-block;

    /* min-width : 13px;
    min-height: 13px; */
    height: 60%;
    width: auto;
}

.LinkItem>span {
    margin-left: 13px;
}

.LinkItem.Active {
    background-color: var(--07-vucos-grey);
    border-radius   : 8px;
}

.LinkItem.Focused,
.LinkItem:hover {
    background-color: var(--main-color);
    color           : var(--main-text);
    border-radius   : 8px;
}