.Container {
	width : 100vw;
	height: 100vh;

	overflow: hidden;

	background-repeat    : no-repeat;
	background-size      : cover;
	background-position-y: top;
	background-position-x: center;

	display: flex;
}

.Container>* {
	margin-top: 9vh;
	height    : 91vh;

	overflow-y: hidden;

}

.Navbar {
	min-width: fit-content;
	max-width: fit-content;

	padding: 0px 5vw 30px 30px;
}


.NavItem {
	font-weight: 400;
	font-size  : var(--font-19);
	line-height: var(--line-120);

	padding: 13px 20px;

	margin: 5px 0;

	width: auto;
}

.Content {
	flex: 1 1 0;
}

.SectionContainer {
	width : 100%;
	height: 91vh;

	overflow-y: auto;
}

.SectionContainer>div {
	margin-bottom: 25px;
}

.SectionContainer>div:last-of-type {
	margin-bottom: 90vh;
}

.WarningContainer {
	height: 40px;
}

.WarningContainer img {
	height      : 100%;
	margin-right: 12px;
}