.LinkItem {
    height       : 50px;
    margin-bottom: 20px;

    color: var(--07-vucos-grey);

    overflow     : hidden;
    border-radius: 100px;

    display        : flex;
    justify-content: center;
    align-items    : center;

    cursor: pointer;
}

.Text {
    white-space  : nowrap;
    text-overflow: ellipsis;

    padding-right: 8px;
    padding-left : 20px;

    min-width: 132px;
    height   : 100%;

    display        : flex;
    justify-content: center;
    align-items    : center;

    background: var(--03-1-vucos-grey);

    font-weight: bold;
    font-size  : var(--font-17);
    line-height: var(--line-120);
}

.Icon {
    /* width : 51px; */
    height       : 100%;
    padding-right: 20px;
    padding-left : 8px;

    display        : flex;
    justify-content: center;
    align-items    : center;

    background: #ACACAC;

}

.LinkItem.Focused,
.LinkItem:hover * {
    color: var(--main-text);
}

.Focused .Text ,
.LinkItem:hover .Text  {
    background: var(--main-color);
}