.Section {
    /* min-height: 450px; */
    min-height: 45vh;

    position: relative;
    z-index : 0;
}

.VideoContainer {
    width : 70%;
    height: 70vh;

    overflow: hidden;

    position: absolute;
    top     : 0;
    right   : 0;

    z-index: 2;
}

.ImgContainer {
    width : 70%;
    height: 70vh;

    position: absolute;
    top     : 0;
    right   : 0;

    z-index: 1;

    overflow: hidden;
}

.ImgContainer img {
    width          : 100%;
    height         : 100%;
    object-fit     : cover;
    object-position: center;
}

.Filter {
    position: absolute;
    top     : 0;
    right   : 0;

    width : 102%;
    height: 104%;

    background: linear-gradient(90deg, var(--09-vucos-grey) 6.41%, rgba(0, 0, 0, 0) 61.5%), linear-gradient(0deg, var(--09-vucos-grey) 0%, rgba(0, 0, 0, 0) 43.83%);
}

.Content {
    position: absolute;

    top : 119px;
    left: 80px;

    width: 55%;
}

.Content>* {
    position: relative;
    z-index : 2;
}