.Container {
    display: inline-block;

    text-align: center;

    width: fit-content
}

.Header {
    font-weight: 400;
    font-size  : var(--font-25);
    line-height: var(--line-100);

    text-align: center;

    color: var(--main-color);
}

.Img {
    width        : 120px;
    height       : 120px;
    border-radius: 50%;
    object-fit   : cover;

    margin: 30px 0;
}

.NameInput input {
    background: transparent;
    border    : none;

    font-weight: 700;
    font-size  : var(--font-22);
    line-height: var(--line-120);
    text-align : center;

    padding-left: 0;

    color      : var(--main-text);
    caret-color: var(--main-color);
}