.Content {
	height: 100vh;

	overflow  : hidden;
	overflow-y: auto;

	margin-top: 20px;

	background: var(--09-vucos-grey);
	color     : var(--04-vucos-grey)
}

.Panel {
	background: var(--09-vucos-grey);

	padding: 5vh 0;
}

.Header {
	font-weight: 400;
	font-size  : var(--font-19);
	line-height: var(--line-120);

	color: var(--main-text);
}

.Header,
.Content>div {
	margin-left: 5vw;
}

.Content>div:last-of-type {
	margin-bottom: 50vh;
}