.Container {
    position   : absolute;
    top        : 0;
    left       : 0;

    z-index: 20;

    height: 100%;

    margin-top: 15px;
}

.Space {
    width : 10px;
    height: 15px;
}

.Content {
    display       : flex;
    flex-direction: column;
    align-items   : center;

    width: 70px;

    height: 100%;
}

.Container p {
    background: var(--red);

    font-weight: 700;
    font-size  : var(--font-20);
    line-height: var(--line-75);

    color: var(--main-text);

    padding: 5px 0 9px;
    width  : 100%;

    text-align: center;

    position: sticky;
    top     : 0;
}

.Line {
    background: var(--red);
    width     : 2px;
    height    : 100%;
}