.Container {
    display: flex;

    padding: 30px 65px;

    background: linear-gradient(90deg, var(--09-vucos-grey) 0%, var(--11-vucos-grey) 100%);

    overflow-x: auto;
}

.Container>* {
    margin: 0 10px;

    color: var(--main-text) !important;
}