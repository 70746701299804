.arrow {
    display: inline-block;

    margin: 8px;
}

.left {
    width        : 0;
    height       : 0;
    border-top   : 10px solid transparent;
    border-bottom: 10px solid transparent;

    border-right: 10px solid var(--main-text);
}

.right {
    width        : 0;
    height       : 0;
    border-top   : 10px solid transparent;
    border-bottom: 10px solid transparent;

    border-left: 10px solid var(--main-text);
}

.arrow-container>* {
    animation-name: opacity;

    opacity                  : 0;
    animation-iteration-count: infinite;
    animation-duration       : 1.2s;
    animation-timing-function: ease-in-out;

}

/* .arrow-container span:nth-of-type(1) {
    animation-delay: 0.3s;
} */

.arrow-container span:nth-of-type(2).right {
    animation-delay: 0.2s;
}

.arrow-container span:nth-of-type(3).right {
    animation-delay: 0.4s;
}

.arrow-container span:nth-of-type(2).left {
    animation-delay: 0.2s;
}

.arrow-container span:nth-of-type(1).left {
    animation-delay: 0.4s;
}

@keyframes opacity {

    0%,
    40%,
    100% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }
}