.Content {
    margin: 60px 50px 0;

    display        : flex;
    justify-content: flex-start;
    flex-wrap      : wrap;

    max-height: calc(100vh - 78px);
    overflow-y: auto;
}

.Content>div {
    --movie-width : calc(var(--movie-w) * 0.5);
    --movie-height: calc(var(--movie-h) * 0.5);
}

@media(min-width: 1600px) {
    .Content>div {
        --movie-width : calc(var(--movie-w) * 0.6);
        --movie-height: calc(var(--movie-h) * 0.6);
    }
}