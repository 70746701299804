.Container {
    width : 100%;
    height: 100%;

    padding-right: 20px;

}

.Content {
    width: 100%;

    display: flex;
}

.Content>* {
    margin-right: 12px;

    min-width: fit-content;
}


.Heading {
    margin-bottom: 21px;
}

.Header {
    font-weight: 400;
    font-size  : var(--font-24);
    line-height: var(--line-120);

    color: var(--main-text);

    margin-bottom: 35px;
}

.Subheader {
    font-size  : var(--font-21);
    font-weight: 400;
    line-height: var(--line-120);

    margin-bottom: 11px;
}

.Text {
    font-weight: 400;
    font-size  : var(--font-20);
    line-height: var(--line-120);

    color: var(--04-vucos-grey);
}

.ItemBox {
    background-color: #202027;

    padding: 30px 33px 30px 27px;

    border-radius: 8px;

    /* margin-right: 12px; */

    width: 45%;
}

.ItemBox>* {
    margin-bottom: 35px;
}

.ItemBox>*:last-child {
    margin-bottom: 0;
}