.Container {
    /* margin-left: 157px; */
    /* margin-top : 122px; */
    padding-left: 50px;
    margin-top  : 105px;

    max-width: 90%;

    display       : flex;
    flex-direction: column;

    height    : calc(100vh - 105px);
    overflow-y: auto;

    padding-bottom: 50%;
}

.LoadingContainer {
    position: relative;

    width: 100%;
}

.Item {
    margin-bottom: 50px;

    display    : flex;
    align-items: center;
}

.Item>* {
    margin-right: 26px;
}

.Item>*:last-child {
    margin-right: 0;
}


.Icon {
    width: 75px;

    text-align: center;
}

.Icon svg {
    height: 35px;
}

.Info {
    font-weight: 400;
    font-size  : var(--font-20);
    line-height: var(--line-120);

    color: var(--02-vucos-grey);

    width: 43%;
}

.Button {
    min-height: unset;
    height    : 50px;
    width     : 200px;

    padding-left : 10px;
    padding-right: 10px;

    font-weight: 400;
    font-size  : var(--font-19);
    line-height: var(--line-120);
}

.ActiveDevice {
    position: relative;
}

.ActiveDevice * {
    color: var(--main-color);
    fill : var(--main-color);
}

.ActiveDevice::before {
    content: "";

    display: inline-block;

    width : 10px;
    height: 10px;

    border-radius: 50%;

    background-color: var(--main-color);

    position: absolute;
    left    : -5px;

    animation: appear 1s infinite;
}

.ActiveText {
    width : 200px;

    padding-left : 10px;
    padding-right: 10px;

    font-weight: 400;
    font-size  : var(--font-19);
    line-height: var(--line-120);

    text-align: center;
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 0.6;
    }

    100% {
        opacity: 1;
    }
}