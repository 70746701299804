.Item {
    display    : flex;
    align-items: center;

    position: relative;

    width : 25px;
    height: 25px;
}

.Input {
    position: absolute;
    opacity : 0;
    cursor  : pointer;
    height  : 0;
    width   : 0;
}

.Mark {
    position        : absolute;
    top             : 0;
    left            : 0;
    height          : 25px;
    width           : 25px;
    background-color: transparent;

    border       : 3px solid var(--04-1-vucos-grey);
    border-radius: 5px;
}

.Input.Active~.Mark {
    border          : none;
    background-color: var(--main-color);
}

.Mark:after {
    content : "";
    position: absolute;
    display : none;
}

.Input.Active~.Mark:after {
    display: block;
}

.Mark:after {
    left  : 10px;
    top   : 3px;
    width : 4px;
    height: 14px;

    border           : solid white;
    border-width     : 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform    : rotate(45deg);
    transform        : rotate(45deg);
}

.Text {
    font-weight: 500;
    font-size  : var(--font-25);
    line-height: 120%;

    color: var(--main-text);
}