.Container {
    position: fixed;
    top     : 0;
    left    : 0;

    z-index: 2;

    height: 100vh;
    width : 100vw;

    display: flex;
}

.SubMenu {
    position: relative;
    
    max-width: calc(350px + 80px);
    min-width: calc(350px + 80px);

    height: 100%;
}

.Content {
    width : 100%;
    height: 100vh;

    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 18.92%, rgba(22, 22, 29, 0.8) 80.51%),
        linear-gradient(90deg, #16161D 0%, rgba(22, 22, 29, 0) 26.91%),
        linear-gradient(180deg, #16161D 0%, rgba(0, 0, 0, 0) 70.88%);
}