.Link,
.ActiveLink {
    font-size: var(--font-30);
    line-height    : 120%;
    font-weight    : 400;
    text-align     : center;
    text-decoration: none;

    min-width: 208px;

    padding: 0px 28px 21px;
}

.Link {
    color: var(--02-vucos-grey);
}

.ActiveLink {
    color   : var(--02-vucos-grey);
    position: relative;
}

.FocusLink {
    color   : var(--main-color);
    position: relative;
}

.ActiveLink::after {
    content      : "";
    position     : absolute;
    bottom       : 0px;
    left         : 0;
    width        : 100%;
    height       : 8px;
    background   : var(--02-vucos-grey);
    border-radius: 100px;
}

.ActiveLink.FocusLink::after {
    background: var(--main-color);
}