.Content {
    position: relative;

    color: var(--03-vucos-grey);
}

.Type {
    font-weight: 400;
    font-size  : var(--font-20);
    line-height: var(--line-120);

    color: var(--main-color);

    margin-bottom: 17px;
}

.ParentTitle {
    font-weight: bold;
    font-size  : var(--font-21);
    line-height: var(--line-120);

    color: var(--main-color);

    margin-bottom: 10px;

    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.Header {
    font-weight: 700;
    font-size  : var(--font-30);
    line-height: var(--line-120);

    color: var(--main-text);

    margin-bottom: 17px;

    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.Detail {
    display    : flex;
    align-items: center;
    flex-wrap  : wrap;

    font-weight: 400;
    font-size  : var(--font-19);
    line-height: var(--line-120);

    color: var(--03-vucos-grey);
}

.Detail>* {
    margin-right: 35px;
}

.Year {
    border       : 1px solid var(--04-vucos-grey);
    border-radius: 4px;

    padding: 2px 10px;
}

.Duration {}

.RatingContainer {
    display    : flex;
    align-items: center;
}

.RatingContainer>img {
    width : 27px;
    height: 25px;

    margin-right: 10px;
}

.Rating {}

.License {
    font-weight: 400;
    font-size  : var(--font-20);
    line-height: var(--line-120);

    color: var(--03-vucos-grey);

    margin-top: 17px;
}

.Description {
    width: 70%;

    font-weight: 500;
    font-size  : var(--font-18);
    line-height: 125%;

    margin: 17px 0px 30px 0;
}

.WarningContainer {
    height: 36px;
}

.WarningContainer img {
    height: 100%;

    margin-right: 20px;
}

/* CONTINUE INFO */

.ContinueInfo {
    display    : flex;
    align-items: center;
}

.ContinueLine {
    width : 200px;
    height: 4px;

    background: var(--04-vucos-grey);

    margin-right: 10px;
}

.WatchedLine {
    height   : 100%;
    max-width: 100%;

    background: var(--main-color);
}

.ContinueDuration {
    width           : fit-content;
    color           : var(--main-text);
    background-color: hsla(240, 7%, 18%, 0.9);
    align-self      : flex-end;

    font-weight: 400;
    font-size  : var(--font-19);
    line-height: var(--line-120);

    padding: 10px 10px;
}