.BottomLogo {
    position: fixed;
    bottom  : -50px;
    left    : 0;

    width: 100vw;

    display        : flex;
    justify-content: center;
    align-items    : end;

    pointer-events: none;
}

.BottomLogo img {
    width     : 230px;
    max-width : 230px;
    max-height: 230px;
}