.FullWidthContainer {
    overflow: hidden;

    position: fixed;
    top     : 0;
    left    : 0;
    z-index : 2;

    height: 100vh;
    width : 100vw;

    display: flex;

    background: var(--09-vucos-grey);
}

.EmptyContainer {}


.Container {
    margin-top : 10vh;
    margin-left: 20px;

    height    : 90vh;
    overflow-y: auto;

    width: 100%;

    padding-bottom: 40%;
}

/* .Container>div:last-of-type {
    margin-bottom: 80%;
} */

.SubMenu {
    position: relative;

    min-width: fit-content;
    max-width: fit-content;

    height: 100%;
}

.FocusItem {
    height: calc(var(--movie-h) * 0.3 + 8px * 2 + 3px * 2);
}