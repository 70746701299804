.Container {
    display       : flex;
    flex-direction: column;

    height: 100vh;

    overflow: hidden;
}

.SelectedDate {
    min-height: 100px;
    max-height: 100px;
}

.Container ::-webkit-scrollbar {
    width : 0;
    height: 0;
}

.ContainerDetail {
    display: flex;

    height    : 100vh;
    overflow-y: scroll;
}

.ChannelContainer {
    min-width: fit-content;

    overflow: scroll;

    /* position: relative;
    top     : 100px; */
    margin-top: 100px;
}

.ContainerEpg {
    width: calc(600px * 24);
    /*24 hour width - 600px per hour*/

    overflow: scroll;

    position   : relative;
    margin-left: 20px;
    width      : 100%;

    display       : flex;
    flex-direction: column;
}

.ContainerEpg::-webkit-scrollbar {
    width : 3px;
    height: 3px;
}

.SelectedDate {
    position: absolute;
    top     : 0;
    left    : 0;
    z-index : 20;

    min-width: 150px;

    display        : flex;
    justify-content: center;
    align-items    : flex-end;

    padding-bottom: 20px;

    background: var(--09-vucos-grey);

    font-weight: 400;
    font-size  : var(--font-20);
    line-height: var(--line-120);

    color: var(--main-text);
}