.Content {
    position: relative;

    color: var(--03-vucos-grey);
}

.Logo {
    width     : 70px;
    max-height: 70px;

    margin-bottom: 17px;
}

.Header {
    font-weight: 700;
    font-size  : 2.2rem;
    line-height: 120%;

    color: var(--main-text);

    margin-bottom: 17px;

    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.Detail {
    display    : flex;
    align-items: center;

    font-weight: 400;
    font-size: var(--font-23);
    line-height: 136%;

    color: var(--03-vucos-grey);
}

.Detail>* {
    margin-right: 35px;
}

.Name {
    color: var(--main-color);
}

.Button {
    margin-top   : 85px;
    margin-bottom: 60px;

    display        : flex;
    justify-content: center;
    align-items    : center;

    width: 280px;
}

.Button svg path {
    fill: inherit;
}

.ButtonFocused svg path {
    fill: var(--main-color);
}