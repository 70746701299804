.Container {
    position: fixed;
    top     : 0;
    left    : 0;
    z-index : 299;

    height: 100vh;
    width : 100vw;

    display        : flex;
    justify-content: center;
    align-items    : center;
}

.Background {
    position: absolute;
    top     : 0;
    left    : 0;
    z-index : -1;

    width : 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.491);
}

.Content {
    position: relative;
    bottom  : -1000px;

    z-index: 100;

    height: fit-content;
    width : fit-content;

    max-width : 50vw;
    min-width : 250px;
    min-height: 200px;
    padding   : 64px 39px 56px;

    background: var(--09-vucos-grey);
    color     : var(--main-text);

    box-shadow   : 0px 4px 100px var(--dark-color);
    border-radius: 12px;

    animation-name           : frombottom;
    animation-duration       : 1s;
    animation-fill-mode      : forwards;
    animation-timing-function: ease-in-out;
}

@keyframes frombottom {
    from {
        bottom: -1000px;
    }

    to {
        bottom: 0px;
    }
}

.ActionContainer {
    margin-top: 30px;

    display        : flex;
    justify-content: space-between;
    align-items    : center;
}

.ActionContainer.Center {
    justify-content: center;
}

.Header {
    font-size  : var(--font-25);
    font-weight: 700;
    line-height: var(--line-120);

    margin-bottom: 24px;
}

.Text {
    font-size  : var(--font-18);
    font-weight: 400;
    line-height: 33px;

    margin-bottom: 48px;
}