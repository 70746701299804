.Item {
    display    : flex;
    align-items: center;

    width  : 100%;
    padding: 0px;

    height: 100%;
}

.ImgContainer {
    margin-right: 20px;

    min-width: calc(var(--movie-w) * 0.3);
    max-width: calc(var(--movie-w) * 0.3);
    height   : 100%;
}

.ImgContainer img {
    width : 100%;
    height: 100%;

    object-fit     : cover;
    object-position: center;

    border-radius: 4px;
}

.ContentContainer {
    width       : 100%;
    margin-right: 10px;
}

.Title {
    font-weight: 700;
    font-size  : var(--font-21);
    line-height: var(--line-120);

    color: var(--main-text);

    margin-bottom: 16px;
}

.Text {
    font-weight: 500;
    font-size  : var(--font-19);
    line-height: var(--line-120);

    color: var(--04-1-vucos-grey);

    display           : -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}