.Content>article {
    margin-bottom: 65px;
}

.Header {
    font-weight: 400;
    font-size  : 35px;
    line-height: 150%;

    color: var(--02-vucos-grey_headings);

    margin-bottom: 40px;
}

.Step {
    display: inline-block;

    border       : 1px solid var(--02-vucos-grey);
    border-radius: 8px;

    font-weight: 400;
    font-size  : var(--font-20);
    line-height: var(--line-150);

    padding      : 2px 15px;
    margin-bottom: 5px;
}

.Text {
    font-weight: 300;
    font-size  : var(--font-25);
    line-height: var(--line-150);

    margin-bottom: 15px;
}

.BigText {
    font-weight: 700;
    font-size  : 35px;
    line-height: 150%;

}