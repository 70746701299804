.AuthorizeBox {
    width: calc(100% - 5px);

    max-width: unset;

    margin-right: 5px;
}

.Button {
    width: 100%;

    background: var(--08-vucos-grey);

    border-radius: 8px;

    padding      : 20px 15px;
    margin-bottom: 15px;

    width: 100%;
}

.ButtonContent {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
}

.ButtonContent svg {
    display: inline-block;
}

.Text {
    font-weight: 500;
    font-size  : var(--font-19);
    line-height: 120%;

    color: var(--main-text);

    margin-right: 20px;
}