.Item {
    width: calc(25% - 20px);
}

.Img {
    width : 100%;
    height: 150px;

    object-fit: cover;
}

.Name {
    margin-top   : 10px;
    margin-bottom: 10px;

    font-weight: 500;
    font-size  : var(--font-20);
    line-height: var(--line-120);

    color: var(--03-vucos-grey);
}

.Title {
    font-weight: 700;
    font-size  : var(--font-21);
    line-height: var(--line-100);

    min-height: calc(var(--font-21) * 2);

    color: var(--main-text);

    display           : -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.Time {
    margin-top   : 4px;
    margin-bottom: 10px;

    display    : inline-block;
    font-weight: 500;
    font-size  : var(--font-19);
    line-height: var(--line-120);

    color: var(--02-vucos-grey);
}

.Stat {
    display        : flex;
    justify-content: center;
    align-items    : center;
    padding        : 17px 12px;
    background     : var(--08-vucos-grey);
    border-radius  : 4px;
}

.Stat>p {
    font-weight: 500;
    font-size  : var(--font-20);
    line-height: 125%;
    color      : var(--02-vucos-grey);
    margin-left: 8px;
}