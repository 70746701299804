.Container {
    display: flex;

    padding-top: 5vh;
    height     : 100vh;

    overflow: hidden;
}

.ChannelContainer,
.EpgContainer {
    height: calc(100vh - 5vh);

    overflow-y: auto;
    overflow-x: hidden;
}

.ChannelContainer {
    padding: 0 5px 0 3vw;

    min-width: 40%;
    max-width: 40%;
}

.EpgContainer {
    padding: 0 5px 0 1vw;

    min-width: 60%;
    max-width: 60%;
}

.EpgContainer>div:last-of-type {
    margin-bottom: 50vh;
}