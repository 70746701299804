/* .Container {} */

/* .TextContent {} */

.Heading {
    color: var(--main-color);

    font-size  : 35px;
    line-height: var(--line-200);
    font-weight: 400;
}

.Text {
    font-size  : var(--font-30);
    line-height: var(--line-150);
    font-weight: 300;
}

.Form {
    margin-top: 126px;
}

.Buttons {
    display        : flex;
    justify-content: space-between;

    position: absolute;
    left    : 0;

    padding-left : 105px;
    padding-right: 103px;
    bottom       : 123px;

    width: 52.09%;
}