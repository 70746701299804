.Navbar {
    background: linear-gradient(90deg, var(--09-vucos-grey) 0%, var(--11-vucos-grey) 100%);

    padding: 105px 29px 94px 57px;

    display        : flex;
    flex-direction : column;
    justify-content: space-between;

    position: relative;
}

.List {
    list-style: none;
}

.List li {
    margin-bottom: 8px;
}

.NavLink {
    display: block;

    text-decoration: none;
}

.NavLink,
.Button {
    font-weight: 400;
    font-size  : var(--font-18);
    line-height: var(--line-120);

    color: var(--main-text);

    padding: 15px 23px;

    border       : 1px solid var(--none);
    border-radius: 8px;
}

.NavLink.Active {
    background: var(--07-vucos-grey);
    color     : var(--main-text);
}

.NavLink.Focused,
.Button.Focused,
.NavLink:hover,
.Button:hover {
    background  : var(--main-color);
    color       : var(--main-text);
    border-color: var(--main-color);
}

.Button {
    width : 100%;
    height: 60px;

    display        : flex;
    justify-content: flex-start;
    align-items    : center;
}

.Button svg {
    margin-right: 19px;
    height      : 100%;
}

.Button path {
    fill: var(--02-vucos-grey);
}

.Button.Focused path,
.Button:hover path {
    fill: var(--main-text);
}

.VersionData {
    position: absolute;

    bottom   : 40px;
    left     : 135px;
    /* left  : calc(57px + 23px); */

    font-weight: 500;
    font-size  : var(--font-17);
    line-height: var(--line-100);

    color: var(--02-vucos-grey);
}