.Container {
    width    : 55vw;
    max-width: 700px;
    min-width: 300px;

    height: 100vh;
}

.ImgContainer { 
    height: 50%;

    position: relative;

    display           : flex;
    align-items       : flex-end;
    /* justify-content: center; */

    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(22, 22, 29, 0.6) 47.4%, var(--09-vucos-grey) 100%);
}

.Img {
    height: 100%;
    width : 100%;

    object-fit     : cover;
    object-position: center;

    position: absolute;
    top     : 0;
    left    : 0;
    z-index : -1;

    background-color: var(--09-vucos-grey);
}

.ItemContent,
.ActionContainer {
    padding-left : 30px;
    padding-right: 30px;

}

.ItemContent {
    position: relative;
}

.Logo {
    height       : 105px;
    max-width    : 100px;
    object-fit   : cover;
    margin-bottom: 0;
}

.Header {
    font-weight: 700;
    font-size  : var(--font-23);
    line-height: var(--line-120);

    color: var(--main-text);

    margin-bottom: 19px;
}

.Time {
    display: inline-block;

    font-weight: 400;
    font-size  : var(--font-18);
    line-height: 136%;

    color: var(--03-vucos-grey);

    margin-bottom: 13px;
}

.Text {
    font-weight: 500;
    font-size  : var(--font-20);
    line-height: var(--line-100);

    color: var(--03-vucos-grey);

    display           : -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;

    min-height: calc(var(--font-20) * 1);
    /* max-height: calc(var(--font-20) * 3 - 1px); */
}

.ActionContainer {
    background: var(--09-vucos-grey);

    padding-top: 40px;

    height: 100%;
    width : 100%;

    display       : flex;
    flex-direction: column;
    align-items   : flex-start;
}

.ActionContainer>div {
    margin-bottom: 20px;
}

.Button {
    border: transparent;

    font-weight: 400;
    font-size  : var(--font-19);

    color: var(--main-text);

    height   : 60px;
    width    : fit-content;
    min-width: 250px;

    margin-bottom: 5px;

    padding-right: 20px;
    padding-left : 20px;

    display    : flex;
    align-items: center;
}

.Focused {
    color: var(--main-text);

    background   : var(--main-color);
    border-radius: 8px;
}

.Button svg {
    margin-right: 20px;

    width: var(--font-19);
}