.Container {
    padding: 20px 30px;

    background: linear-gradient(90deg, var(--09-vucos-grey) 0%, var(--11-vucos-grey) 100%);
}

.Wrapper {
    display: flex;

    /* overflow-x: auto; */
    width: fit-content;
}

.Wrapper>* {
    margin: 0 10px 5px;
}

.Wrapper>*:first-child {
    margin-left: calc(50vw - (var(--channel-w) / 2) - 40px);
}

.Wrapper>*:last-child {
    margin-right: calc(50vw - (var(--channel-w) / 2) - 40px);
}