.Item {
    text-align: center; 
}

.ImgContainer,
.Title {
    max-width: calc(var(--movie-w) * 0.38 + 10px);
}

.ImgContainer {
    position: relative;

    display        : flex;
    justify-content: center;
}

.Img {
    width     : calc(var(--movie-w) * 0.38);
    height    : calc(var(--movie-h) * 0.38);

    object-fit: cover;

    border-radius: 4px;
}

.Title {
    margin-top: 5px;

    font-weight: 300;
    font-size: var(--font-18);
    line-height: var(--line-120);

    /* color: var(--05-vucos-grey); */
    color: var(--main-text);

    display           : -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.Playing .Title {
    font-weight: 700;

    color: var(--main-color);
}