.Section {
    padding-top : 101px;
    padding-left: 68px;
    margin-right: 68px;

    overflow: hidden;
    height  : 100%;
    width   : 100%;

    background: var(--09-vucos-grey);
}

.Header {
    font-style : normal;
    font-weight: 700;
    font-size  : var(--font-20);
    line-height: var(--line-120);

    display    : flex;
    align-items: center;

    color: var(--main-text);

    margin-bottom: 30px;
}

.Container {
    margin-top: 38px;
}

.ContentSection>* {
    margin-bottom: 5px;
}

.Placeholder {
    font-style : normal;
    font-weight: 400;
    font-size  : var(--font-20);
    line-height: var(--line-120);

    color: var(--main-color);
}

.ContentSection {
    height    : calc(100vh - 101px - 62px);
    overflow-y: auto;

    scroll-padding: 20px 0;
}

.ContentSection>article:last-of-type {
    margin-bottom: 100px;
}

@media(max-height: 900px) {
    .Section {
        padding-top: 80px;
    }

    .ContentSection {
        height: calc(100vh - 80px - 62px);
    }

}