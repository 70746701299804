.Item {
    display: inline-block;

    position: relative;

    cursor: pointer;

    width : calc(100% / 5);

    max-height: calc(var(--movie-h) * 0.6);
    max-width : var(--movie-w);

    /* padding: 6px; */

    border: 3px solid transparent;

}

.ImageWrapper {
    width : 100%;
    height: 100%;

    /* background-color: #6c6a6a45; */
    overflow     : hidden;
    border-radius: 4px;

    /* border: 5px solid var(--09-vucos-grey); */

}

.ImageWrapper>img {
    width     : 100%;
    height    : 100%;
    object-fit: cover;

    /* to hide title text if there is visible img */
    position: relative;
    z-index : 1;
}

.Focused.Item,
.Item:hover {
    border-color: var(--main-color);

    border-radius: 4px;
}

.Focused.Item .ImageWrapper,
.Item:hover .ImageWrapper {
    border-radius: 0;
}

.Title {
    position  : absolute;
    bottom    : 10%;
    width     : 80%;
    min-height: 58px;
    left      : 10%;
    text-align: left;

    font-weight: 700;
    font-size  : var(--font-20);
    line-height: 120%;

    color: var(--main-text);

    display           : -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

@media(max-width: 1300px) {
    .Item {
        width: calc(100% / 4);
    }
}

@media(max-width: 1000px) {
    .Item {
        width: calc(100% / 3);
    }
}