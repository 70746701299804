.Container {
    display: flex;

    padding: 30px 65px;

    background: linear-gradient(90deg, var(--09-vucos-grey) 0%, var(--11-vucos-grey) 100%);
}

.ActionList {
    width    : 26%;
    max-width: 500px;
    min-width: fit-content;

    display       : flex;
    flex-direction: column;

    margin-right: 20px;
}

.Link,
.ActiveLink,
.FocusLink {
    border: none !important;

    display    : flex !important;
    align-items: center !important;

    height: 50px !important;
    width : fit-content !important;

    margin-bottom: 12px !important;
    padding      : 0 22px 0 22px !important;

}

.Link svg,
.ActiveLink svg,
.FocusLink svg {
    margin-right: 27px !important;

    height: 50%;
    width: auto;
}

.Links a:last-of-type {
    margin-bottom: 0 !important;
}

.Link,
.ActiveLink,
.FocusLink {
    font-style : normal !important;
    font-weight: 400 !important;
    /* font-size  : 30px !important;  */
    line-height: 120% !important;

    text-decoration: none !important;
}

/* 
.Link,
.Link svg, */
.Link {
    /* color: var(--04-1-vucos-grey) !important;
    fill : var(--04-1-vucos-grey) !important; */
    color: white !important;
    fill : white !important;
}

.FocusLink,
.ActiveLink {
    color: white !important;
    fill : white !important;

    background-color: var(--main-color) !important;
    border-radius   : 8px !important;
}