.PlayerLine {
    width : 100vw;
    height: 10px;

    padding: 0 8vw;

    position: relative;
}

.FocusLine {
    border  : 3px solid var(--04-vucos-grey);
    height  : 44px;
    position: absolute;
    left    : 0;
    top     : -12px;
    width   : calc(100% - 8vw - 8vw);
    margin  : 0 8vw;
}

.TimeDetail,
.PlayerLine time {
    font-size: var(--font-20);
    color: var(--main-text);

    display        : flex;
    justify-content: flex-end;
    align-items    : center;

    margin-top: 20px;
}

.HoverTip>time {
    font-size: var(--font-20);
    color: var(--main-text);

    display        : flex;
    justify-content: flex-end;
    align-items    : center;
}

.TimeDetail.Space {
    justify-content: space-between;
}

.HoverPoint {
    position: relative;
    height  : 14px;

    cursor: pointer;
}

.HoverTip {
    position: absolute;
    bottom  : 30px;
    left    : 0;
}