.Item {
	text-align: center;
	/* cursor    : pointer; */
}

.Time {
	font-weight: 400;
	font-size  : var(--font-19);
	line-height: 120%;
	/* color   : var(--05-vucos-grey); */
	color      : var(--main-text);

	display: inline-block;
}

.ImgContainer {
	position       : relative;
	display        : flex;
	justify-content: center;

	margin: 10px 0;
}

.Future {
	cursor: not-allowed !important;
}

.Img {
	width           : calc(var(--channel-w) * 0.6);
	height          : calc(var(--channel-h) * 0.6);
	object-fit      : contain;
	border-radius   : 4px;
	/* margin       : 15px 0; */
}

.Logo {
	height  : 50px;
	position: absolute;
	bottom  : 10px;
}

.Title {
	font-weight: 300;
	font-size  : var(--font-19);
	line-height: 120%;

	color: var(--main-text);

	display           : -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow          : hidden;
	text-overflow     : ellipsis;
}

.Playing .Title {
	font-weight: 700;
	color      : var(--main-color);
}