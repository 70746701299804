.Item {
    font-weight: 400;
    font-size: var(--font-30);
    line-height: 120%;

    color: var(--02-vucos-grey);

    width           : 300px;
    padding      : 20px 20px 20px 20px;
    /* margin-bottom: 15px; */

    border-radius: 8px;
    border       : 1px solid transparent;

    cursor: pointer;
}

.Selected {
    background: var(--07-vucos-grey);
}

.Focused,
.Item:hover {
    background  : var(--main-color);
    border-color: var(--main-color);
    color: var(--main-text);
}