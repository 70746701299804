.Wrapper {
    display       : flex;
    flex-direction: column;
}

.ItemRow,
.PlaceholderRow {
    min-height: calc(120px - 25px);
    max-height: calc(120px - 25px);

    position: relative;

    margin-bottom: 25px;
}

.PlaceholderRow {
    display: flex;
}

.ItemRow>div:last-of-type {
    margin-right: 10vw;
}

.Loading {
    width : calc(10px * 24 * (60 / 5) + 10vw);
    height: calc(100vh - 180px - 170px);

    background-color         : var(--none);
    animation-name           : blink;
    animation-duration       : 2s;
    animation-iteration-count: infinite;

    position: relative;

    top: -15px;
    /*loading fix*/
}

.Space {
    width : calc(10px * 24 * (60 / 5 + 1) + 10vw);
    height: calc(100vh - 180px - 170px);
}

@keyframes blink {
    from {
        background-color: hsla(240, 7%, 18%);
    }

    to {
        background-color: var(--none);
    }
}

.EpgPlaceholder {
    display: inline-block;

    width: 100%;

    cursor: unset;

    background: rgba(43, 43, 49, .5);

    color: var(--05-vucos-grey);

    border-right: 8px solid var(--09-vucos-grey);

    cursor: default;
}

.PlaceholderTitle {
    font-weight: 400;
    font-size  : var(--font-18);
    line-height: var(--line-100);

    color: var(--03-vucos-grey);

    height: 100%;

    display        : flex;
    justify-content: center;
    align-items    : center;

    text-align: center;
}