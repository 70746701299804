.Content {
    position: relative;

    color: var(--03-vucos-grey);

}

.Type {
    font-weight: 400;
    font-size  : 15px;
    line-height: 136%;

    color: var(--main-color);

    margin-bottom: 10px;
}

.ParentTitle {
    font-weight: bold;
    font-size  : var(--font-21);
    line-height: var(--line-120);

    color: var(--main-color);

    margin-bottom: 10px;

    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.Header {
    font-weight: 700;
    font-size  : var(--font-30);
    line-height: 120%;

    color: var(--main-text);

    margin-bottom: 17px;

    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.Detail {
    display       : flex;
    align-items   : center;
    /* flex-wrap  : wrap; */

    font-weight: 400;
    font-size  : var(--font-14);
    line-height: 136%;

    color: var(--03-vucos-grey);

    margin-bottom: 10px;
}

.Detail>* {
    margin-right: 20px;

    text-wrap: nowrap;
}

.Year {
    border       : 1px solid var(--04-vucos-grey);
    border-radius: 4px;

    padding: 2px 10px;
}

.Duration {}

.RatingContainer {
    display    : flex;
    align-items: center;
}

.RatingContainer>img {
    width : 27px;
    height: 25px;

    margin-right: 10px;
}

.Rating {}

.LikeContainer {
    display    : flex;
    align-items: center;
}

.LikeButton {
    height: 100%;
    border: none;

    margin: 0 10px;

    color: #FDFDFD;

    transition: color 0.5s ease-in;

    display    : flex;
    align-items: center;

    font-size: var(--font-17);
}

.LikeButton svg {
    /* lg = 50px */
    width : 40px;
    height: 40px;

    margin-right: 10px;
}

.LikeButton:hover circle {
    color       : var(--main-color);
    fill-opacity: 1;
}

.SelectedLike,
.SelectedLike circle {
    fill-opacity: 1;
    color       : var(--main-color);
}

.License {
    font-weight: 400;
    font-size  : var(--font-20);
    line-height: 136%;

    color: var(--03-vucos-grey);

    margin-top: 17px;
}

.Description {
    width: 80%;

    font-weight: 500;
    font-size  : var(--font-19);
    line-height: 125%;

    margin: 17px 0px 30px 0;

    display           : -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;

    /* min-height: 113px; */
}

.WarningContainer,
.QualityContainer {
    height: 36px;
}

.WarningContainer img,
.QualityContainer img {
    height: 100%;

    margin-right: 20px;
}

/* CONTINUE INFO */

.ContinueInfo {
    display    : flex;
    align-items: center;
}

.ContinueLine {
    width : 200px;
    height: 4px;

    background: var(--04-vucos-grey);

    margin-right: 10px;
}

.WatchedLine {
    height   : 100%;
    max-width: 100%;

    background: var(--main-color);
}

.ContinueDuration {
    width           : fit-content;
    color           : var(--main-text);
    background-color: hsla(240, 7%, 18%, 0.9);
    align-self      : flex-end;

    font-weight: 400;
    font-size  : var(--font-18);
    line-height: 120%;

    padding: 10px 10px;
}

.SeasonInfo {
    font-weight: 400;
    font-size  : var(--font-18);
    line-height: 120%;

    color: var(--main-text);
}