.Item {
	display    : flex;
	align-items: center;

	padding      : 11px 20px 15px 30px;
	border       : 3px solid transparent;
	border-radius: 4px;

	cursor: pointer;

	position: relative;
}

.Item.Focused,
.Item:hover {
	border-color: var(--main-color);
}

.TimeContainer {
	display    : flex;
	align-items: center;
	justify-content: center;

	margin-right: 20px;

	font-weight: 400;
	font-size  : var(--font-18);

	color: var(--04-vucos-grey);

	min-width: 100px;
	max-width: 100px;
}

.UnreadIcon {
	/* margin-right: 20px; */

	position: absolute;
	left    : 10px;

	width: 10px;
}

.ContentContainer {
	width: 100%;
}

.Header {
	font-weight: 700;
	font-size  : var(--font-19);
	line-height: var(--line-120);

	color: var(--main-text);

	margin-bottom: 2px;

	display           : -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow          : hidden;
	text-overflow     : ellipsis;
}

.Header.Read {
	color: var(--03-vucos-grey);
}

.Content {
	font-weight: 400;
	font-size  : var(--font-18);
	line-height: var(--line-120);

	color: var(--03-vucos-grey);

	display           : -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow          : hidden;
	text-overflow     : ellipsis;
}

.Item:not(.Focused) .Content {
	width: 100%;
}

.ActionContainer {
	display: flex;

	margin-left: 10px;
}

.ActionContainer>* {
	margin-right: 10px;
}

.ActionContainer>*:last-of-type {
	margin-right: 0;
}

.ButtonRead,
.ButtonDelete {
	border-radius: 4px;

	font-weight: 400;
	font-size  : var(--font-17);
	line-height: var(--line-120);

	color: var(--main-text);

	padding: 10px 0;

	height: unset;


	flex: 0 0 50%;
}


.ButtonDelete.Focused,
.ButtonRead.Focused {
	color: var(--main-text);

	background: var(--main-color);
}