.ProfileContainer {
    display: flex;

    /* margin-bottom: -30px; */
    margin-bottom: 0;
}


.ProfileContainer>div {
    margin-right: 36px;
}

.ProfileImg {
    width : 100px;
    height: 100px;
}

.Duck {
    width : 33px;
    height: 33px;
}

.ProfileName {
    font-weight: 400;
    font-size  : var(--font-30);
    line-height: var(--line-80);

    text-align: center;

    color: var(--02-vucos-grey);
}

.Focused {
    color: var(--main-color) !important;
}