.Wrapper {
    display: flex;

    min-height: 100px;
    max-height: 100px;

    width : max-content;

    padding-bottom: 9px;

    background: var(--09-vucos-grey);

    position: sticky;
    top: 0;

    z-index: 1;
}

.Wrapper>div:last-of-type {
    margin-right: 10vw;
}

.Item {
    display        : flex;
    flex-direction : column;
    justify-content: flex-end;

    height: 100%;

    text-align: left;

    position: relative;
    /*to hold underline*/

    font-weight: 400;
    font-size  : var(--font-16);
    line-height: var(--line-100);

    color: var(--main-text);
}

.Item>span {
    margin-bottom: 9px;
}

.Invisible {
    visibility: hidden;
}

.Line {
    width : 100%;
    height: 2px;

    background: var(--08-vucos-grey);

    /* for invisible head hours, show underline */
    visibility: visible;
}