.Container {
    background-color: var(--09-vucos-grey);

    width  : 100%;
    display: flex;

    padding-top: 9vh;

    height  : 100vh;
    overflow: hidden;
}

.SearchSection {
    width: 36.5%;
}

.ResultSection {
    width: calc(100% - 36.5%);

    padding-right: 30px;
}