.Item {}

.HeaderContainer {
    padding: 20px;

    background-color: var(--06-vucos-grey);

    border-radius: 10px;
}

.Title {
    font-weight: 700;
    font-size: var(--font-21);
    line-height: var(--line-120);

    color: var(--main-text);
}

.Text {
    padding: 20px;

    font-weight: 400;
    font-size: var(--font-19);
    line-height: var(--line-125);

    color: var(--03-vucos-grey);
}

