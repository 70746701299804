.VideoContainer {
    position: relative;
    width   : 100%;
    height  : 100%;

    display        : flex;
    justify-content: flex-end;

    transition: opacity 1s ease-out;
}

.VideoContainer>video,
.Filter {
    width : 86%;
    height: 86%;

    z-index: -1;

    transform: scale(1.5);
}

.Filter {
    position: absolute;
    top     : 0;
    right   : 0;

    background: linear-gradient(90deg, var(--09-vucos-grey) 6.41%, rgba(0, 0, 0, 0) 61.5%), linear-gradient(0deg, var(--09-vucos-grey) 0%, rgba(0, 0, 0, 0) 43.83%);
}