.Container {
	position           : relative;
	top                : 0;
	left               : -1000px;

	z-index            : 1;

	max-width          : 390px;
	min-width          : 390px;
	
	height             : 100%;
	padding            : 11vh 5vw 10px 3vw;
	padding-right      : 41px;

	background         : radial-gradient(100% 15.78% at 0% 54.4%, var(--09-vucos-grey) 55.73%, var(--10-vucos-grey) 100%);
	
	animation-name     : moveToRight;
	animation-duration : 1s;
	animation-fill-mode: forwards;
}

@keyframes moveToRight {
	from {
		left: -1000px;
	}

	to {
		left: 0px;
	}
}

.HeaderContainer {
	display      : flex;
	align-items  : center;
}

.Header,
.Link {
	border     : none;
	padding    : 0.8rem;
	min-width  : unset;
	height     : unset;
	width      : fit-content;
	width      : auto;
	text-align : left;
}

.Header {
	font-weight: 700;
	font-size  : var(--font-18);
	line-height: 120%;
	color      : var(--main-text);
	display    : flex;
	align-items: center;

	margin-bottom: 10px;
}

.Header>svg {
	/* margin: 10px 20px 10px 10px; */
	margin-right: 10px;
	margin-left: 10px;
	height: var(--font-18);
}

.Link {
	margin-left  : 16px;
	font-weight  : 400;
	font-size    : var(--font-17);
	line-height  : 125%;
	color        : var(--04-1-vucos-grey);

	margin-bottom: 5px;
	padding      : 15px 15px;
}

.FocusedHeader,
.Header:hover {
	color     : var(--main-color) !important;
	background: transparent !important;
}

.Content {
	display       : flex;
	flex-direction: column;
}