.Content {
    width    : 55vw;
    max-width: 700px;
    min-width: 300px;

    height: 100vh;
}

.ImgContainer {
    height: 50%;

    position: relative;

    display    : flex;
    align-items: flex-end;

    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(22, 22, 29, 0.6) 47.4%, var(--09-vucos-grey) 100%);

}

.Img {
    height         : 100%;
    width          : 100%;
    object-fit     : cover;
    object-position: top;

    background: var(--09-vucos-grey);

    position: absolute;
    top     : 0;
    left    : 0;
    z-index : -1;
}

.ItemContent,
.ActionContainer {
    padding-left: 30px;

}

.ItemContent {
    position: relative;

    /* width: 81%; */
}

.Header {
    font-weight: 700;
    font-size  : var(--font-23);
    line-height: 120%;

    color: var(--main-text);

    margin-bottom: 19px;
}

.Detail {
    display    : flex;
    align-items: center;
    flex-wrap  : wrap;

    font-weight: 400;
    font-size  : var(--font-19);
    line-height: 136%;

    color: var(--03-vucos-grey);
}

.Detail>* {
    margin-right : 23px;
    margin-bottom: 10px;
}

.Detail>*:last-child {
    margin-right: 0;
}

.Year {
    border       : 1px solid var(--04-vucos-grey);
    border-radius: 4px;

    padding: 2px 10px;
}

.Description {
    font-weight: 500;
    font-size  : var(--font-20);

    color: var(--03-vucos-grey);

    margin-top: 15px;

    display           : -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;

    min-height: calc(var(--font-20) * 1);
    /* max-height: 65px; */
}

.ActionContainer {
    background: var(--09-vucos-grey);

    padding-top: 40px;

    height: 100%;
    width : 100%;

    display       : flex;
    flex-direction: column;
    align-items   : flex-start;
}

.Button {
    border: transparent;

    font-weight: 400;
    font-size  : var(--font-19);

    color: var(--main-text);

    height   : 60px;
    width    : fit-content;
    min-width: 250px;

    margin-bottom: 5px;

    padding-right: 20px;
    padding-left : 20px;

    display    : flex;
    align-items: center;
}


.Button.Focused {
    color: var(--main-text);

    background   : var(--main-color);
    border-radius: 8px;
}

.Button svg {
    margin-right: 20px;

    width: var(--font-19);
}