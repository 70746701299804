.Container {
    margin-left: 50px;

    padding-top  : 105px;
}

.Content {
    margin-top: 40px;
}

.LoadingContainer {
    position: relative;

    width: 100%;
}

.Title {
    font-size  : var(--font-24);
    line-height: var(--line-120);
    font-weight: 400;
    text-align : left;
}

.Content>* {
    width: 100%;

    padding-left: 20px;

    max-width: unset;
}