.Container {
    width: 90%;

    display       : flex;
    flex-direction: column;
    align-items   : center;

    margin: 102px auto 87px;

    text-align: center;
}

.Header {
    font-weight: 700;
    font-size  : var(--font-24);
    line-height: var(--line-120);

    color: var(--main-text);

    width: 70%;
}

.Text {
    margin-top: 20px;

    font-weight: 400;
    font-size  : var(--font-20);
    line-height: var(--line-120);

    color: var(--03-vucos-grey);

    width: 80%;
}