/* .Container {
    max-height: calc(100% - 51px - 70px - 68px - 68px);
    overflow-y: auto;
} */

.Wrapper {
    width: 100%;

    margin-left : 0;
    margin-right: 0;

    padding-left : 50px;
    padding-right: 50px;
}

.Wrapper button {
    margin-left: 50px;
}

.Content>*>* {
    margin-left: 0 !important;

    width: 100%;
}

.Content h2 {
    font-weight: 400;
    font-size  : var(--font-22);
    line-height: var(--line-100);

    color: var(--main-text);
}

.RowItem {
    margin-bottom: 25px !important;
}

.ThemeItem {
    position: relative;

    min-width: calc(1920px * 0.1);
    max-width: calc(1920px * 0.1);

    height: calc(1080px * 0.1);

    margin-right: 10px;
    margin-bottom: 5px;

    display        : flex;
    justify-content: center;
    align-items    : center;
}

.ThemeItem:last-child {
    /* margin-right: calc(100% - 1920px * 0.1); */
}

.ThemeItem p {
    color: var(--main-text);

    font-weight: bold;
    font-size  : var(--font-16);
    line-height: var(--line-100);

    max-width: 90%;

    text-align: center;

}

.Selected p {
    color: var(--main-color);
}

.ThemeItem img {
    position: absolute;
}

.Left {
    top : 0;
    left: 0;

    height: 100%;
}

.Right {
    top  : 0;
    right: 0;

    height: 100%;
}

.Top {
    top : 0;
    left: 0;

    width: 100%;
}

.Bottom {
    bottom: 0;
    left  : 0;

    width: 100%;
}

.Random {
    background-color: var(--red);
}

.Original {
    background-color: rgba(0, 0, 0, 0.4);
}

.SwitchItem {
    display: inline-block;
}