.Item {
    display: inline-block;

    position: relative;

    cursor: pointer;
}

.ImageWrapper {
    width : calc(var(--movie-w) * 0.43);
    height: calc(var(--movie-h) * 0.43);

    position: relative;

    border-radius: 4px;
    border       : 5px solid transparent;
}

.Focused .ImageWrapper,
.Item:hover .ImageWrapper {
    border-color: var(--main-color);
}

.ImageWrapper img {
    width : 100%;
    height: 100%;

    border-radius: 4px;
}

.TitleContainer {
    position: absolute;
    bottom  : 0%;

    height : 35%;
    z-index: -1;

    padding: 5%;

    width: 100%;

    background-color: rgba(0, 0, 0, 0.244);

}

.Title {
    text-align: left;

    font-weight: bold;
    color      : var(--main-text);


    display           : -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;

}

.OverIcon {
    visibility: hidden;

    position: absolute;
    top     : 0;
    left    : 0;

    height: 100%;
    width : 100%;

    display        : flex;
    justify-content: center;
    align-items    : center;
}

.Item:hover .OverIcon {
    visibility: visible;
}