.Container {
    background: linear-gradient(90deg, var(--09-vucos-grey) 0%, var(--11-vucos-grey) 100%);

    height: 100%;

    display        : flex;
    justify-content: space-between;
}

.ProfileSection {
    background-position: right;
    background-size    : cover;

    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;

    width: 47.5%;
}

.Content {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;

    width: fit-content;
}

.Header {
    font-weight: 400;
    font-size  : var(--font-25);
    line-height: var(--line-100);

    text-align: center;

    color: var(--main-color);
}

.Img {
    width : 150px;
    height: 150px;

    margin: 15vh 0 5vh;
}

.NameInput {
    margin-bottom: 10vh;
}

.NameInput input {
    background: transparent;
    border    : none;

    font-weight: 700;
    font-size  : var(--font-23);
    line-height: var(--line-120);
    text-align : center;

    color      : var(--main-text);
    caret-color: var(--main-color);

    padding-left: 0 !important;
}

.Button {
    font-weight: 400;
    font-size  : var(--font-19);
    line-height: var(--line-120);

    width : 250px;
    height: 50px;
}