.Buttons {
    display: flex;
    margin : 30px 0;
}

.Button {
    color       : var(--main-color);
    border-color: var(--main-color);

    margin-right : 25px;
    padding-left : 20px;
    padding-right: 20px;

    display        : flex;
    justify-content: center;
    align-items    : center;
}

.ButtonSmall {
    color          : var(--main-color);
    border-color   : var(--main-color);
    margin-right   : 25px;
    padding-left   : .8rem;
    padding-right  : .8rem;
    display        : flex;
    justify-content: center;
    align-items    : center;
    min-width      : auto;
}

.Button svg {
    margin-right: 10px;

    height: 55%;
    width : fit-content;
}

.Modal {
    padding: 0;
}


.BackButton {
    color       : var(--main-color);
    border-color: var(--main-color);

    margin-right : 25px;
    padding-left : .8rem;
    padding-right: .8rem;

    display        : flex;
    justify-content: center;
    align-items    : center;
    min-width      : auto;
}

.BackButtonArrow {
    transform: scale(1.5);

    margin-right: 10px;
    margin-bottom: 4%;
}