.Container {
    height : 100vh;
    padding: 60px 20px 72px 48px;

    background-color: var(--09-vucos-grey);

    display           : flex;
    flex-direction    : column;
    /* justify-content: space-between; */

    position: relative;
}

.LargeContainer {
    position: relative;
    z-index : 3;
}

.NavItems {
    display       : flex;
    flex-direction: column;
    align-items   : flex-end;

    font-style : normal;
    font-weight: 400;
    font-size  : var(--font-27);
    line-height: 120%;

    margin-top: 72px;

    z-index: 2;
}

.MenuTitle {
    overflow     : hidden;
    white-space  : nowrap;
    text-overflow: ellipsis;
}

.LinkContainer {
    display    : flex;
    align-items: center;

    /* height: 60px; */
}

.Link,
.ActiveLink,
.FocusLink {
    /* height: 60px; */
    width: 290px;

    margin-bottom: 3px;
    padding      : 0 28px 0 22px;
}

.Link.Highlighted:not(.FocusLink),
.Link.Highlighted:not(.FocusLink) svg {
    color: var(--main-text);
    fill : var(--main-text);
}


.Link svg,
.ActiveLink svg,
.FocusLink svg {
    margin-right: 40px;
    height      : 60px;
}

.NavItems a:last-of-type {
    margin-bottom: 0;
}

.SpaceAfter {
    margin-bottom: 64px;
}

.Link,
.ActiveLink {
    font-style : normal;
    font-weight: 400;
    font-size  : 1.2rem;
    line-height: 120%;

    text-decoration: none;
}

.Link,
.Link svg {
    /* color: var(--02-vucos-grey);
    fill : var(--02-vucos-grey); */

    color: var(--main-text);
    fill : var(--main-text);
}

.ActiveLink,
.ActiveLink svg {
    /* color: var(--02-vucos-grey);
    fill : var(--02-vucos-grey); */

    color: var(--main-text);
    fill : var(--main-text);
}


.ActiveLink {
    background   : var(--07-vucos-grey);
    border-radius: 8px;
}

.FocusLink,
.FocusLink svg,
.Link:hover,
.Link:hover svg {
    color: var(--main-text);
    fill : white;
}

.FocusLink,
.Link:hover {
    background-color: var(--main-color);
    border-radius   : 8px;
}

/* Small Navbar */
.SmallContainer {
    padding: 40px 8px 10px 42px;
    width  : 80px;

    justify-content: flex-start;
    align-items    : center;

    position: relative;
}

.SmallContainer .NavItems {
    margin-top: calc(204px - 40px);
}

.SmallContainer .MenuTitle {
    display: none;
}

.SmallContainer .Link,
.SmallContainer .ActiveLink,
.SmallContainer .FocusLink {
    display: block;

    /* height: 60px; */
    width: fit-content;

    /* margin-bottom: 32px; */
    padding: 0;

    background: none;
}

.SmallContainer .Link svg,
.SmallContainer .FocusLink svg {
    /* fill: var(--06-vucos-grey); */

    color: var(--main-text);
    fill : var(--main-text);
}

.SmallContainer .ActiveLink svg,
.SmallContainer .Link:hover svg {
    fill: var(--main-color);
}

.SmallContainer .OtherNavItems {
    display: none;
}

.Img {
    width     : 46px;
    height    : 46px;
    object-fit: cover;
}

@media(max-height: 875px) {
    .Container {
        padding: 30px 20px 50px 48px;
    }

    .Link svg,
    .ActiveLink svg,
    .FocusLink svg {
        height: 60px;
    }

    .NavItems {
        margin-top: 50px;
    }

    .SmallContainer .NavItems {
        margin-top: calc(204px - 80px);
    }

    .SpaceAfter {
        margin-bottom: 30px;
    }
}

@media(max-height: 750px) {
    .Container {
        padding: 20px 20px 50px 48px;
    }

    .Link svg,
    .ActiveLink svg,
    .FocusLink svg {
        height : 45px;
        padding: 8px 0;
    }

    .NavItems {
        margin-top: 30px;
    }

    .SmallContainer .NavItems {
        margin-top: 15vh;
    }

    .SpaceAfter {
        margin-bottom: 30px;
    }
}