.Container {
    display       : flex;
    flex-direction: column;

    height  : 100vh;
    overflow: hidden;
}

.SectionMiniProfile {
    display    : flex;
    align-items: center;

    font-weight: 400;
    font-size  : var(--font-21);
    line-height: var(--line-100);

    color: var(--02-vucos-grey);

    padding-bottom: 50px;
}

.ActiveImg {
    margin-right: 15px;

    width        : 60px;
    height       : 60px;
    border-radius: 50%;

    object-fit: cover;
}

.SectionImages {
    /* margin-top: 111px; */

    display     : flex;
    flex-wrap   : wrap;
    /* width    : calc(120px * 4 + 130px * 4); */

    overflow-y: auto;

    padding-bottom: 100px;
}

.SectionImages>div {
    position: relative;

    margin: 25px 30px;
}


/* .Focused {
    position: absolute;
    top     : 0;
    right   : 0;

    width : 80px;
    height: 80px;
} */