.Item {
    display       : flex;
    flex-direction: column;
    align-items   : center;

    position: relative;

    cursor: pointer;

    width: calc(100% / 5);

    border: 5px solid transparent;
}

.ImageWrapper {
    width : 100%;
    height: 100%;

    overflow     : hidden;
    border-radius: 4px;
}

.ImageWrapper>img {
    width     : 100%;
    max-height: 130px;

    object-fit: contain;

    /* to hide title text if there is visible img */
    position: relative;
    z-index : 1;
}

/* .Focused .ImgContainer,
.Item:hover .ImgContainer {
    border: 5px solid var(--main-color);
} */

.Focused.Item,
.Item:hover {
    border-color: var(--main-color);

    border-radius: 4px;
}

.TextContainer {
    width: 100%;

    display        : flex;
    justify-content: center;
    align-items    : center;
}

.Text {
    font-weight: 400;
    font-size  : var(--font-18);
    line-height: 100%;

    color: var(--main-text);

    margin-top   : 5px;
    margin-bottom: 5px;

    text-align: center;
}