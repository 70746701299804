    .Container {
        position: fixed;
        z-index : 2;

        top : 0;
        left: 0;

        width : 100vw;
        height: 100vh;

        display        : flex;
        justify-content: center;
        align-items    : center;

        background-color: rgba(0, 0, 0, 0.5);
    }

    .Content {
        display       : flex;
        flex-direction: column;
        align-items   : center;

        width     : 35vw;
        text-align: center;
    }

    .Content>* {
        width: 100%;
    }

    .Header {
        font-weight: 700;
        font-size  : var(--font-40);
        line-height: 120%;

        /* 06-vucos-grey */
        color: var(--main-text);

        margin-bottom: 18px;
    }

    .Text {
        font-weight: 400;
        font-size  : var(--font-25);
        line-height: 120%;

        /* 06-vucos-grey */
        color: var(--main-text);

        margin-bottom: 20px;
    }

    .Input input {
        font-size  : var(--font-32);
        font-weight: 400;

        color: var(--main-text);
        text-align: center;

        padding-left: 0;
    }

    .Input input[type='password'] {
        font-family   : Verdana;
        letter-spacing: 0.125em;
    }

    .ActionContainer>* {
        margin: 5px 15px;
    }