.Item {
    display: inline-block;

    position: absolute;
    z-index : 0;

    height: 100%;

    background: var(--09-vucos-grey);

    cursor: pointer;
}


.Focused.Item {
    z-index: 1;
}


.Content {
    position: relative;

    height: 100%;

    width: 100%;


    overflow: hidden;
}


.Focused .TextContainer,
.Item:hover .TextContainer {
    background: var(--main-color);
    color     : var(--main-text);
}

.TextContainer {
    width: 100%;

    margin : 0 5px;
    padding: 10px 10px;

    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;

    height: 100%;

    background: hsla(240, 7%, 18%, 0.5);
    color     : var(--05-vucos-grey);

}

.TimeContainer {
    display    : flex;
    align-items: center;
}

.TimeContainer svg {
    margin-left: 10px;

    width : 20px;
    height: auto;
}

.EpgHour {
    font-weight: 400;
    font-size  : var(--font-17);
    line-height: var(--line-120);

    /* 05 */
    /* color: hsla(240, 1%, 42%, 0.5); */
    color  : var(--02-vucos-grey);
    opacity: 0.5;

    /* white-space: nowrap; */

    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;

    text-align: center;
}

.EpgTitle {
    font-weight: 400;
    font-size  : var(--font-18);
    line-height: var(--line-100);

    color: var(--03-vucos-grey);

    margin-top: 10px;

    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;

    text-align: center;
}

.Focused .EpgTitle,
.Focused .EpgHour,
.Item:hover .EpgTitle,
.Item:hover .EpgHour {
    color  : var(--main-text);
    opacity: 1;
}