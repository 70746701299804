.Container {
    display        : flex;
    justify-content: space-between;

    /* padding: 100px 100px; */
    padding: 30px 65px;

    background: linear-gradient(90deg, var(--09-vucos-grey) 0%, var(--11-vucos-grey) 100%);
}

.Container>* {
    margin-right: 15px;

    height: max-content;
}

.Container>*:last-child {
    margin-right: 0;
}
 
.ActionList {
    width    : 26%;
    max-width: 400px;

    display       : flex;
    flex-direction: column;
}

.Link,
.ActiveLink,
.FocusLink {
    display    : flex !important;
    align-items: center !important;

    height: 50px !important;
    width : fit-content !important;

    margin-bottom: 12px !important;
    padding      : 0 18px !important;

    font-style     : normal !important;
    font-weight    : 400 !important;
    /* font-size      : 30px !important; */
    line-height    : 120% !important;
    text-decoration: none !important;
    border         : none !important;
}

.Link svg,
.ActiveLink svg,
.FocusLink svg {
    margin-right: 27px !important;

    height: 50%;
    width: auto;
}

.ActionList a:last-of-type {
    margin-bottom: 0 !important;
}

.Link {
    /* color: var(--04-1-vucos-grey) !important; */
    /* fill : var(--04-1-vucos-grey) !important; */
    color: white !important;
    fill : white !important;
}

.FocusLink,
.ActiveLink {
    color: white !important;
    fill : white !important;

    background-color: var(--main-color) !important;
    border-radius   : 8px !important;
}

.NextEpg {
    /* height: 230px; */
    width : 20%;

    display        : flex;
    flex-direction : column;
    justify-content: space-between;

    /* padding-left: 30px; */
}

.Headline {
    font-weight: 400;
    font-size: var(--font-20);
    line-height: 136%;

    /* color: var(--05-vucos-grey); */
    color: var(--main-text);
}

.Title {
    font-weight: 700;
    font-size: var(--font-30);
    line-height: 120%;

    /* color: var(--04-vucos-grey); */
    color: var(--main-text);

    margin-bottom: 18px;

    /* display           : -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis; */
}

.Time {
    font-weight: 400;
    font-size: var(--font-20);
    line-height: 136%;

    color: var(--main-text);

    white-space: nowrap;
}