.Item {
    display: inline-block;

    position: relative;
    overflow: hidden;

    cursor: pointer;
}

.ImageWrapper {
    position: relative;

    overflow: hidden;
}

.OverLayer {
    position: absolute;
    top     : 0;
    left    : 0;

    border-radius: 4px;

    width : 100%;
    height: 100%;

    display        : flex;
    justify-content: center;
    align-items    : center;


    background: rgba(0, 0, 0, 0.6);
}

.ChannelItem .ImageWrapper {
    width : calc(var(--channel-w) * 0.7);
    height: calc(var(--channel-h) * 0.7);
}

.VODItem .ImageWrapper {
    width : calc(var(--movie-w) * 0.45);
    height: calc(var(--movie-h) * 0.45);
}

.ChannelItem .ImgContainer {
    display        : flex;
    justify-content: center;
    align-items    : flex-end;
}

.ImgContainer {
    width : 100%;
    height: 100%;

    background-size    : cover !important;
    background-position: center !important;

    border       : 5px solid var(--11-vucos-grey);
    border-radius: 4px;

    display        : flex;
    justify-content: center;
    align-items    : flex-end;
}

.Focused .ImgContainer,
.Item:hover .ImgContainer {
    border-color: var(--main-color);
}

.Logo {
    max-height: 50px;
    min-height: 35px;

    margin-bottom: 12px;
}

.OverIcon {
    position: absolute;
    top     : 0;
    left    : 0;

    height: 100%;
    width : 100%;

    display        : flex;
    justify-content: center;
    align-items    : center;
}

.Title {
    position: absolute;

    bottom: 0;
    width : 80%;

    font-weight: bold;
    font-size  : var(--font-22);

    /* 02-vucos-grey */
    color: var(--main-text);

    margin: 0 10% 20%;

    display           : -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow          : hidden;
}